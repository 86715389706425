import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import win1 from '../../assets/inst/win/win1.png';
import win2 from '../../assets/inst/win/win2.png';
import win3 from '../../assets/inst/win/win3.png';
import win4 from '../../assets/inst/win/win4.png';
import win5 from '../../assets/inst/win/win5.png';
import win6 from '../../assets/inst/win/win6.png';
import instwin from '../../assets/inst/win/instwin.png';
import instwin1 from '../../assets/inst/win/instwin1.png';
import instwin2 from '../../assets/inst/win/instwin2.png';
import firefox from '../../assets/inst/win/firefox.png';
import edge from '../../assets/inst/win/edge.png';
import opera from '../../assets/inst/win/opera.png';



import explorer from '../../assets/pdf/Browser_Windows.pdf';

const DocInstWin = (props) => {
    const { type } = props;
    const classes = useStyles();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />

            <div>
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'Bai Jamjuree',
                        fontWeight: 'bold',
                        fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                        marginLeft: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: { mobile: 'center', laptop: 'left' },
                    }}
                >
                    Descarga e instala Klarway (Chrome)
                    {/* <Box sx={{ display: { mobile: 'none', laptop: 'initial' } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography
                                className={classes.text}
                                sx={{
                                    maxWidth: { laptop: '90vw' },
                                    fontSize: { mobile: '2.2rem', laptop: '1.1rem' },
                                    fontWeight: 'bold',
                                }}
                            >
                                Si usas Edge o Firefox{' '}
                            </Typography>
                            <Button
                                href={explorer}
                                target={'_blank'}
                                rel="noreferrer"
                                variant="contained"
                                sx={{
                                    textDecoration: 'none',
                                    textDecorationLine: 'none',
                                    fontSize: { mobile: '1.7rem', laptop: '1.1rem' },
                                    margin: { mobile: '40px auto', laptop: '0px' },
                                }}
                            >
                                hacé click acá
                            </Button>{' '}
                        </Box>
                    </Box> */}
                </Typography>
                <Divider />
                {/* <Box sx={{ display: { mobile: 'initial', laptop: 'none' } }}> */}
                    {/* <Box
                        className={classes.boxes}
                        sx={{
                            margin: { mobile: '70px auto', laptop: '30px auto' },
                            flexDirection: { mobile: 'row', laptop: 'row' },
                            textAlign: 'center',
                            borderRadius: '12px',

                            width: { mobile: '80%', laptop: '80%' },
                        }}
                    >
                        <Typography
                            className={classes.text}
                            sx={{
                                maxWidth: { laptop: '90vw' },
                                fontSize: { mobile: '2rem', laptop: '1.1rem' },
                                fontWeight: 'bold',
                            }}
                        >
                            Si usas Edge o Firefox{' '}
                        </Typography>
                        <Button
                            href={explorer}
                            target={'_blank'}
                            rel="noreferrer"
                            variant="contained"
                            sx={{
                                textDecoration: 'none',
                                textDecorationLine: 'none',
                                fontSize: { mobile: '1.7rem', laptop: '1.1rem' },
                                margin: { mobile: '10px auto', laptop: '0px' },
                            }}
                        >
                            hacé click acá
                        </Button>{' '}
                    </Box>
                </Box> */}

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    
                    
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Selecciona tu sistema operativo e instala Klarway.
                    </Typography>
                    <Box
                        component="img"
                        src={instwin}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                {/* <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Haz click en el link provisto por la institución para descargar Klarway. La descarga comenzará
                        en forma automática.
                    </Typography>
                    <Box
                        component="img"
                        src={win1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box> */}
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    {/* <Box
                    // className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                > */}
                    
                    
                     <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Realizar click en el cuadro de descarga para ejecutar. En caso de no aparecer, ir a la carpeta
                        "Descargas" del navegador web.
                        <Typography
                            variant={'body2'}
                            sx={{
                                fontSize: { mobile: '1.7rem', laptop: '1rem' },
                                color: '#00d478',
                                fontWeight: 'bold',
                            }}
                        >
                            {`- Para acceder rapidamente en Google Chrome (Ctrl + J)`}
                        </Typography>
                    </Typography>
                    
                    <Box
                        component="img"
                        src={instwin1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '20vw' } }}
                    />
                    <Box
                        component="img"
                        src={instwin2}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                    {/* </Box> */}

                </Box>
               
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es firefox lo encontrará aquí.
                    </Typography>

                    <Box component="img" src={firefox} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />

                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es edge lo encontrará aquí.
                    </Typography>

                    <Box component="img" src={edge} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />

                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es opera lo encontrará aquí.
                    </Typography>

                    <Box component="img" src={opera} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />

                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                   
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '22vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        {' '}
                        Al hacer doble click en el archivo descargado anteriormente, se iniciara la instalacion
                        automaticamente.
                    </Typography>

                    <Box
                        component="img"
                        src={win6}
                        className={classes.imagen}
                        sx={{ width: { laptop: '22vw' } }}
                    />

                </Box>
                {/* <Box
                    className={classes.boxes}
                    sx={{
                        paddingTop: '20px',
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Typography
                            className={classes.text}
                            sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                        >
                            Por último, solo queda otorgar permisos para que Klarway pueda proceder con la instalación
                            dando click en "Ejecutar".
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        src={win3}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box> */}

                {/* <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'Bai Jamjuree',
                        fontWeight: 'bold',
                        fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                        marginLeft: '20px',
                        marginTop: '120px',
                        textAlign: { mobile: 'center', laptop: 'left' },
                    }}
                >
                    Instalar Klarway
                </Typography>
                <Divider />

                <Typography className={classes.center} sx={{ fontSize: { mobile: '1.8rem', laptop: '1rem' } }}>
                    Antes de comenzar recomendamos corroborar los{' '}
                    <Link to="/req" className={classes.link}>
                        requisitos
                    </Link>{' '}
                    para el correcto funcionamiento de Klarway. En caso de no alcanzar los recursos necesarios Klarway
                    forzará el inicio de la aplicación, sin embargo, la misma podría sufrir{' '}
                    <b>tiempos de espera más largos</b> y estar abierto a la <b>posibilidad de fallos</b>.
                </Typography>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        {' '}
                        Al hacer doble click en el archivo descargado anteriorente, se iniciara la instalacion
                        automaticamente.
                    </Typography>
                    <Box
                        component="img"
                        src={win6}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>
                <Typography
                    className={classes.center}
                    sx={{ fontWeight: 'bold', fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                >
                    En caso que sea necesario, activar Klarway como app segura.
                </Typography>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        En Windows Defender: <br />
                        <br />
                        Realizar click en <i>"Mas información"</i>, aparecerá un nuevo recuadro como se muestra en la
                        siguiente imagen
                    </Typography>
                    <Box
                        component="img"
                        src={win4}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        padding: '20px',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography
                            className={classes.text}
                            sx={{
                                maxWidth: { laptop: '30vw' },
                                textAlign: 'center',
                                fontSize: { mobile: '1.7rem', laptop: '1rem' },
                            }}
                        >
                            Para iniciar la instalación de Klarway, dar click en <i>"Ejecutar de todas formas"</i>.
                        </Typography>
                        <br />
                        <Typography
                            className={classes.text}
                            sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                        >
                            Para dar permisos en otros antivirus ir a:
                            <br />{' '}
                            <b>
                                Permisos -{'>'} Antivirus -{'>'} "Tu antivirus"
                            </b>
                        </Typography>
                        <br />
                        <Typography
                            className={classes.text}
                            sx={{
                                maxWidth: { laptop: '30vw' },
                                fontSize: { mobile: '1.7rem', laptop: '1rem' },
                                textAlign: 'center',
                            }}
                        >
                            En caso de no estar registrado para poder rendir, hacer click en el siguiente boton.
                            <br />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    margin: '20px auto',
                                    marginRight: '10px',
                                }}
                            >
                                <Link to="/emp" className={classes.noLink}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textDecoration: 'none',
                                            textDecorationLine: 'none',
                                            fontSize: { mobile: '1.7rem', laptop: '1rem' },
                                            margin: { mobile: '40px auto', laptop: '0px' },
                                        }}
                                    >
                                        Registro
                                    </Button>
                                </Link>
                            </Box>
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        src={win5}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box> */}
            </div>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxes: {
            display: 'flex',
            padding: '20px 40px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            backgroundColor: '#fff',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: '#0cbcd8', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { margin: 'auto 20px', fontFamily: 'DINPro' },
        center: { margin: '50px', textAlign: 'center', backgroundColor: '#fff' },
        noLink: { textDecoration: 'none' },
    })
);

export default DocInstWin;
