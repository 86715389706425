import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import sys8 from '../assets/emp/sys/sys8.png';
import sys9 from '../assets/emp/sys/sys9.png';
import sys10 from '../assets/emp/sys/sys10.png';
import sys11 from '../assets/emp/sys/sys11.png';
import sysID from '../assets/emp/sys/sysID.png';

import google from '../assets/playstoreLogo.png';
import apple from '../assets/applestoreLogo.png';
import Avatar from '@mui/material/Avatar';


const EmpBody = ({ type }) => {
    const classes = useStyles();
    console.log(type);
    return (
        <Box>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />

            {/* TERCERA SECCION DE IMAGENES */}

            <Box className={classes.boxesHorizontal} sx={{ flexDirection: { mobile: 'column', laptop: 'row' } }}>
            <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>7</Avatar>

                <Box component="img" src={sys8} className={classes.imgBig} sx={{ width: { laptop: '30vw' } }} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box component="img" src={sys9} className={classes.imgBig} sx={{ width: { laptop: '30vw' } }} />
                    <Typography
                        className={classes.center}
                        sx={{
                            fontSize: { mobile: '1.8rem', laptop: '1rem' },
                        }}
                    >
                        Colocar la identificación siguiendo los patrones que se muestran en pantalla en color{' '}
                        <b>gris</b>. Si la identificación <b>no cumple</b> con el patrón, ubicar la foto de la
                        identificación en la posición de la silueta en gris, como se muestra en la imagen.
                    </Typography>
                </Box>
            </Box>

            {/* <Box
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: { mobile: 'center', laptop: 'space-between' },
                    margin: '60px auto',
                    width: '33vw',
                }}
            >
                <Box
                    sx={{
                        display: { mobile: 'none', laptop: 'initial' },
                        margin: { mobile: '0px auto', laptop: '0px' },
                    }}
                > */}
                    {/* <SvgIcon component={ArrowDownwardIcon} className={classes.icon} /> */}
                {/* </Box> */}
                {/* <SvgIcon component={ArrowDownwardIcon} className={classes.icon} /> */}
            {/* </Box> */}

            <Box
                className={classes.boxesHorizontal}
                sx={{ padding: '15px 40px', flexDirection: { mobile: 'column', laptop: 'row' } }}
            >
            <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-60px'}}>8</Avatar>

                <Box sx={{ padding: '0px 50px' }}>
                    <Box
                        component="img"
                        src={type === 'sys' ? sysID : sys10}
                        className={classes.imagen}
                        sx={{ height: { mobile: '55vh', laptop: '66vh' } }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: { mobile: '', laptop: '350px' },
                        justifyContent: 'space-around',
                    }}
                >
                    {type === 'sys' ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                height: { mobile: '', laptop: '350px' },
                                justifyContent: 'space-around',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: '2rem 0px', laptop: '0px' },
                                }}
                            >
                                En el campo <b>"ID"</b>, completar con el número de identificación personal o el número
                                indicado por la institución.
                                <Divider variant="middle" className={classes.lineColor} />
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: '2rem 0px', laptop: '0px' },
                                }}
                            >
                                Una vez completados todos los datos, hacer click en <b>"ENVIAR"</b>.
                                <Divider variant="middle" className={classes.lineColor} />
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                height: { mobile: '', laptop: '350px' },
                                justifyContent: 'space-around',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: '2rem 0px', laptop: '0px' },
                                }}
                            >
                                En el campo <b>"ID"</b>, completar con el número de identificación personal o el número
                                indicado por la institución.
                                <Divider variant="middle" className={classes.lineColor} />
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: '2rem 0px', laptop: '0px' },
                                }}
                            >
                                Luego de introducir un nombre de usuario, presionar el botón <b>"Verificar"</b>. Si el
                                usuario no está en uso, aparecerá un <b>tilde verde</b>, de lo contrario se mostrará una{' '}
                                <b>cruz roja</b> para modificarlo.
                                <Divider variant="middle" className={classes.lineColor} />
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {/* FIN TERCERA SECCION */}
            <Typography
                sx={{
                    fontSize: { mobile: '3.5rem', laptop: '1.7rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '8rem auto', laptop: '4rem auto' },
                    color: '#14b0ee',
                }}
            >
                En caso de necesitar ayuda para registrarse
            </Typography>
            <Box className={classes.boxesHorizontal} sx={{ flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        fontSize: { mobile: '2rem', laptop: '1.1rem' },
                        marginTop: '20px',
                        color: '#050505',
                        maxWidth: { mobile: '85vw', laptop: '50vw' },
                    }}
                >
                    Presionar sobre <i className={classes.text}>¿Problemas para empadronarte?</i> y completar los datos
                    solicitados. Un auditor validará la información manualmente.
                </Typography>
                <Box
                    component="img"
                    className={classes.image}
                    src={sys11}
                    sx={{ width: { mobile: '100%', laptop: '66vh' } }}
                />
            </Box>
            {/* BOTONES PARA DESCARGAR LA APP */}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: { mobile: '100px auto 60px auto', laptop: '40px auto 50px auto' },
                    width: { mobile: '89vw', laptop: '73vw' },
                    textAlign: 'center',
                    padding: '20px',
                    alignItems: 'center',
                    borderBottom: '2px solid #2bf29c',
                    borderRadius: '100px',
                    backgroundColor: '#fff',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        margin: { mobile: '10px 0px 50px 0px', laptop: '10px 0px 20px 0px' },
                        fontSize: { mobile: '2.2rem', laptop: '1.1rem' },
                    }}
                >
                    Para descargar Klarway en tu celular dirigite a tu tienda!
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        width: { mobile: '75vw', laptop: '40vw' },
                        justifyContent: 'space-around',
                        marginBottom: '10px',
                    }}
                >
                    <Button
                        variant="outlined"
                        className={classes.appleStore}
                        sx={{
                            width: { mobile: '330px', laptop: '170px' },
                            height: { mobile: '90px', laptop: '50px' },
                        }}
                        href="https://apps.apple.com/ar/app/klarway/id1528198239"
                        target="_blank"
                    />
                    <Button
                        variant="outlined"
                        className={classes.googleStore}
                        sx={{
                            width: { mobile: '330px', laptop: '170px' },
                            height: { mobile: '90px', laptop: '50px' },
                        }}
                        href="https://play.google.com/store/apps/details?id=com.bivproctor&hl=es_AR&gl=US"
                        target="_blank"
                    />
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        imagen: {
            margin: '0 auto',
            borderRadius: '10px',
            objectFit: 'fill',
        },
        googleStore: {
            borderRadius: '5px',
            backgroundImage: `url(${google})`,
            backgroundSize: 'cover',
        },
        appleStore: {
            borderRadius: '5px',
            backgroundImage: `url(${apple})`,
            backgroundSize: 'cover',
        },
        imgBig: { maxHeight: '40vh', margin: '20px auto', borderRadius: '50px', objectFit: 'contain' },
        text: { color: '#14b0ee', fontFamily: 'DINPro' },
        center: { margin: '50px', textAlign: 'center' },
        icon: { fontSize: '70px', color: '#9bf7d3' },
        lineColor: { color: '#2bf29c', backgroundColor: '#2bf29c', opacity: 0.5 },
    })
);

export default EmpBody;
