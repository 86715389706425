import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import av15 from '../../../assets/perm/antiVirus/av15.png';
import av16 from '../../../assets/perm/antiVirus/av16.png';
import av17 from '../../../assets/perm/antiVirus/av17.png';
import av26 from '../../../assets/perm/antiVirus/av26.png';
import av27 from '../../../assets/perm/antiVirus/av27.png';
import av28 from '../../../assets/perm/antiVirus/av28.png';
import ava from '../../../assets/perm/antiVirus/ava.png';
import ava1 from '../../../assets/perm/antiVirus/ava1.png';
import ava2 from '../../../assets/perm/antiVirus/ava2.png';
import ava3 from '../../../assets/perm/antiVirus/ava3.png';
import ava4 from '../../../assets/perm/antiVirus/ava4.png';



const Defender = () => {
    const classes = useStyles();
    const defender = [
        { text: 'Ir a INICIO y seleccionar CONFIGURACIÓN.', imagen: av15 },
        { text: 'En el cuadro de búsqueda seleccionar FIREWALL Y PROTECCIÓN DE RED.', imagen: av16 },
        {
            text: 'Seleccionar CONTROL DE APLICACIONES Y EXPLORADOR',
            text2: 'En la sección COMPROBAR APLICACIONES Y ARCHIVOS seleccionar la opción DESACTIVADO.',
            imagen: av17,
        },
        {
            text: 'IMPORTANTE',
            text2: 'Regresar a instalar KLARWAY nuevamente.',
            text3: 'Al terminar de instalar y ejecutar KLARWAY, se debe volver a activar el firewall. Realizar los mismos pasos y en COMPROBAR APLICACIONES Y ARCHIVOS seleccionar la opción ADVERTIR',
            warn: true,
            column: true,
        },

        {
            text: 'Buscar Seguridad de Windows, en el buscador.',
            text2: 'Acceder a Protección antivirus y contra amenazas',
            imagen: ava,
            imagen2: ava1,
            column: true,
        },
        {
            text: '  Hacer click en Administrar la configuración.',
            text2: 'Seleccionar Agregar o quitar exclusiones.',
            text3: 'Elegir el programa o carpeta a excluir, en este caso Klarway.',
            imagen: ava2,
            imagen2: ava3,
            imagen3: ava4,
            column: true,
        },
    ];
    return (
        <Box sx={{
            margin: { mobile: '70px 30px', laptop: '30px' },
            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
        }}>
            
            <Box 
                    sx={{
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
                    }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    width: { mobile: '90vw', laptop: '40vw' },
                    height: { mobile: '20vh', laptop: '15.3rem' },
                    borderRadius: '10px',
                    margin: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                    WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                }}
                component="img"
                src={av26}
            />
            </Box>

            {defender.map(({ text, text2, text3, imagen, imagen2, imagen3, warn, column }) => {
                const ancho = column ? '100%' : '50%';
                const direction = column ? 'column' : 'row';
                return (
                    <Box className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}>
                        <Box sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}>
                            {text && (
                                <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    {text}
                                </Typography>
                            )}
                            {text2 && (
                                <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    {text2}
                                </Typography>
                            )}
                            {text3 && (
                                <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    {text3}
                                </Typography>
                            )}
                        </Box>
                        <Box 
                        sx={{
                            margin: { mobile: '70px 30px', laptop: '30px' },
                            flexDirection: { mobile: 'column-reverse', laptop: 'row' },}}
                        >
                        {imagen && (
                            <Box
                                component="img"
                                src={imagen}
                                className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                            />
                        )}
                        {imagen2 && (
                            <Box
                                component="img"
                                src={imagen2}
                                className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                            />
                        )}
                        {imagen3 && (
                            <Box
                                component="img"
                                src={imagen3}
                                className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                            />
                        )}
                        </Box>
                        
                    </Box>
                );
            })}
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        img1: { margin: '20px auto', borderRadius: '10px', display: "column" },
        imgTitle: { borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { maxWidth: '80vw', flex: 1, padding: '10px', textAlign: 'center', fontFamily: 'DINPro' },
        subMenu: { padding: '10px' },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default Defender;
