import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import r1 from "../../assets/r1.png";
import r2 from "../../assets/r2.png";
import r3 from "../../assets/r3.jpg";
import r4 from "../../assets/r4.jpg";
import r5 from "../../assets/r5.jpg";
import r6 from "../../assets/r6.jpg";
import r7 from "../../assets/r7.png";
import r8 from "../../assets/r8.png";
import r9 from "../../assets/r9.png";
import regrech from "../../assets/regrech.png";
import regrev from "../../assets/regrev.png";
import r10 from "../../assets/r10.png";

const NewEmp = ({ uni }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        margin: { mobile: "70px 30px", laptop: "30px" },
        flexDirection: { mobile: "column-reverse", laptop: "row" },
      }}
    >
      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Typography
          className={classes.text}
          sx={{
            maxWidth: { laptop: "30vw" },
            fontSize: { mobile: "1.7rem", laptop: "1rem" },
          }}
        >
          1. Acepta los "Términos y Condiciones" y "Políticas de Privacidad".
          Luego, haz click en continuar.
        </Typography>
        <Box
          component="img"
          src={r1}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
        <Box
          component="img"
          src={r2}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>
      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Typography
          className={classes.text}
          sx={{
            maxWidth: { laptop: "30vw" },
            fontSize: { mobile: "1.7rem", laptop: "1rem" },
          }}
        >
          2. En el registro se solicitará la fotografía de tu rostro. Ten en
          cuenta las recomendaciones iniciales.
        </Typography>
        <Box
          component="img"
          src={r3}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
        <Box
          component="img"
          src={r4}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>
      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Typography
          className={classes.text}
          sx={{
            maxWidth: { laptop: "30vw" },
            fontSize: { mobile: "1.7rem", laptop: "1rem" },
          }}
        >
          3. Finalmente, se solicitará la fotografía de tu identificación
          personal o credencial. Ten en cuenta las recomendaciones iniciales.
        </Typography>
        <Box
          component="img"
          src={r5}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
        <Box
          component="img"
          src={r6}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>
      {/* <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Typography
          className={classes.text}
          sx={{
            maxWidth: { laptop: "30vw" },
            fontSize: { mobile: "1.7rem", laptop: "1rem" },
          }}
        >
          4. Si se logró validar con éxito tu identidad, el registro es aprobado
          automáticamente.
        </Typography>
        <Box
          component="img"
          src={r7}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box> */}

      <Typography
        // variant="h3"
        sx={{
          letterSpacing: "-1px",
          fontFamily: "DINPro-700",
          fontSize: { mobile: "1rem", laptop: "30px" },
          margin: { mobile: "60px auto", laptop: "inherit" },
        }}
      >
        Resultados del registro
      </Typography>

      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Box
          sx={{
            flexDirection: { mobile: "column-reverse", laptop: "column" },
            margin: { mobile: "70px 30px", laptop: "30px" },
          }}
        >
          <Typography
            className={classes.text1}
            sx={{
              maxWidth: { laptop: "30vw" },
              fontSize: { mobile: "1.7rem", laptop: "1.5rem" },
              color: "#66bb6a",
            }}
          >
            Registro aprobado
          </Typography>
          <Typography
            className={classes.text}
            sx={{
              maxWidth: { laptop: "30vw" },
              fontSize: { mobile: "1.7rem", laptop: "1rem" },
            }}
          >
            Si se logró validar con éxito tu identidad, el registro es aprobado
            automáticamente.
          </Typography>
        </Box>
        <Box
          component="img"
          src={r7}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>

      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Box
          sx={{
            flexDirection: { mobile: "column-reverse", laptop: "column" },
            margin: { mobile: "70px 30px", laptop: "30px" },
          }}
        >
          <Typography
            className={classes.text1}
            sx={{
              maxWidth: { laptop: "30vw" },
              fontSize: { mobile: "1.7rem", laptop: "1.5rem" },
              color: "#FF9635",
            }}
          >
            Registro en revisión
          </Typography>
          <Typography
            className={classes.text}
            sx={{
              maxWidth: { laptop: "30vw" },
              fontSize: { mobile: "1.7rem", laptop: "1rem" },
            }}
          >
            Si no se logró una validación automática, tu registro deberá ser
            aprobado o rechazado por la institución.
          </Typography>
        </Box>
        <Box
          component="img"
          src={regrev}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>

      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Box
          sx={{
            flexDirection: { mobile: "column-reverse", laptop: "column" },
            margin: { mobile: "70px 30px", laptop: "30px" },
          }}
        >
          <Typography
            className={classes.text1}
            sx={{
              maxWidth: { laptop: "30vw" },
              fontSize: { mobile: "1.7rem", laptop: "1.5rem" },
              color: "#DB0000",
            }}
          >
            Registro rechazado
          </Typography>
          <Typography
            className={classes.text}
            sx={{
              maxWidth: { laptop: "30vw" },
              fontSize: { mobile: "1.7rem", laptop: "1rem" },
            }}
          >
            Si la institución rechaza tu registro por algún motivo, deberás
            realizar uno nuevo.
          </Typography>
        </Box>
        <Box
          component="img"
          src={regrech}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>

      <Typography
        // variant="h3"
        sx={{
          letterSpacing: "-1px",
          fontFamily: "DINPro-700",
          fontSize: { mobile: "1rem", laptop: "30px" },
          margin: { mobile: "60px auto", laptop: "inherit" },
        }}
      >
        Consejos para exámenes
      </Typography>

      <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
      >
        <Typography
          className={classes.text}
          sx={{
            maxWidth: { laptop: "30vw" },
            fontSize: { mobile: "1.7rem", laptop: "1rem" },
          }}
        >
          Por último, recieirás algunos consejos para realizar tus exámenes.
        </Typography>
        <Box
          component="img"
          src={r8}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
        <Box
          component="img"
          src={r9}
          className={classes.imagen}
          sx={{ width: { mobile: "80%", laptop: "22vw" } }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    boxesContainer: {
      maxWidth: "80vw",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    boxes: {
      display: "flex",
      padding: "20px 40px",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: " 0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      WebkitBoxShadow: " 0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      backgroundColor: "#fff",
    },
    boxesHorizontal: {
      display: "flex",
      margin: "50px 20px",
      justifyContent: "space-around",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "10px",
      boxShadow: " 0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      WebkitBoxShadow: " 0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      backgroundColor: "#fff",
    },
    imagen: { margin: "20px auto", borderRadius: "10px" },

    imgBig: { width: "60vw", margin: "20px auto", borderRadius: "10px" },
    link: {
      color: "blue",
      fontWeight: "bold",
      textDecoration: "underline",
      cursor: "pointer",
    },
    text: {
      margin: "auto 20px",
      fontFamily: "DINPro",
      textAlign: "left",
      paddingRight: 15,
    },
    text1: {
      margin: "auto 20px",
      fontFamily: "DINPro",
      textAlign: "left",
      paddingBottom: 15,
      fontWeight: "bold",
    },
    center: { margin: "50px", textAlign: "center" },
    icon: { fontSize: "70px", color: "#9bf7d3" },
    rightIcon: {
      fontSize: "70px",
      color: "#9bf7d3",
      transform: "rotate(180deg)",
    },
    leftIcon: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "30px auto",
      marginRight: "7vw",
    },
    lineColor: { color: "#2bf29c", backgroundColor: "#2bf29c", opacity: 0.5 },
  })
);

export default NewEmp;
