import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import cymW1 from '../../assets/perm/camYMic/cymW1.png';
import cymW2 from '../../assets/perm/camYMic/cymW2.png';
import cymW3 from '../../assets/perm/camYMic/cymW3.png';
import cymW4 from '../../assets/perm/camYMic/cymW4.png';

import cymM6 from '../../assets/perm/camYMic/cymM6.png';
import cymM7 from '../../assets/perm/camYMic/cymM7.png';
import cymM8 from '../../assets/perm/camYMic/cymM8.png';
import videoDesk from '../../assets/perm/camYMic/videoDesk.png';
import remove from '../../assets/perm/camYMic/remove.png';
import permisow1 from '../../assets/perm/camYMic/permisow1.png';
import permisow2 from '../../assets/perm/camYMic/permisow2.png';
import permisow3 from '../../assets/perm/camYMic/permisow3.png';
import permisow4 from '../../assets/perm/camYMic/permisow4.png';
import permisow5 from '../../assets/perm/camYMic/permisow5.png';
import permisow6 from '../../assets/perm/camYMic/permisow6.png';

const CamAndMicrophoneWin = ({ type }) => {
    const classes = useStyles();

    const pasosMac = [
        {
            text: 'La primera vez que se inicia Klarway, se solicitarán permisos para el uso de cámara, micrófono y grabación de pantalla.',
            text2: 'Cámara y micrófono: Seleccionar "OK".',
            imagen: cymM6,
            imagen2: cymM7,
            column: true,
        },

        {
            text2: 'El permiso de grabación de pantalla requiere abrir las preferencias del sistema y seguir algunos pasos:',
            imagen: cymM8,
        },
        {
            text: (
                <>
                    <b>Acceder a privacidad, grabación de pantalla y activar Klarway.</b>
                </>
            ),
            imagen: videoDesk,
        },
        {
            text: 'Si luego de otorgar el permiso de grabación a Klarway, el sistema continúa solicitándolo, acceder nuevamente a la pantalla de Seguridad y Privacidad. En el recuadro de permisos, seleccionar Klarway y con el signo menos eliminarla. Posteriormente, agregar nuevamente Klarway, presionando el signo más y seleccionando la app de Klarway en la ventana que se abrirá. ',
            text2: (
                <>
                    <b>Para realizar los cambios es necesario abrir el candado con la clave de MAC.</b>
                </>
            ),
            imagen: remove,
            column: true,
        },
    ];

    const pasosWin = [
        { text: 'En inicio seleccionar "Configuración".', imagen: cymW1 },
        { text: 'Ingresar en "Privacidad".', imagen: cymW2 },
        { text: 'Seleccionar la opción "Micrófono" y controlar que esté activado.', imagen: cymW3 },
        { text: 'Seleccionar la opción "Cámara" y controlar que esté activado.', imagen: cymW4 },
    ];

     const pasos = type === 'm' ? pasosMac : pasosWin;

    return (
        <Box sx={{
            margin: { mobile: '70px 30px', laptop: '30px' },
            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
        }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            {/* <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Permisos Cámara y Micrófono (Windows)
            </Typography>
            <Divider />

            <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Haz click en el link provisto por la institución, en caso de visualizar un cuadro de
diálogo, permite la descarga.
                    </Typography>
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'column' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Podrás visualizar la descarga en el margen inferior izquierdo, tal como se puede ver en
la siguiente imagen.
                    </Typography>
                    <Box
                        component="img"
                        src={permisow1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                    <Box
                        component="img"
                        src={permisow2}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                    <Box
                        component="img"
                        src={permisow3}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                    <Box
                        component="img"
                        src={permisow4}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Una vez finalizada la descarga, abre el archivo.
                    </Typography>
                    <Box
                        component="img"
                        src={permisow5}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Al abrirlo, Klarway se instalará automáticamente.
                    </Typography>
                    <Box
                        component="img"
                        src={permisow6}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box> */}
                
                



            <Box
                sx={{
                    margin: { mobile: '70px 30px', laptop: '30px' },
                    flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                }}
            >
                {pasos.map(({ text, text2, text3, imagen, imagen2, column }) => {
                    const direction = column ? 'column' : 'row';
                    return (
                        <Box className={classes.boxes}
                        sx={{
                            margin: { mobile: '70px 30px', laptop: '30px' },
                            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        }}>
                            <Box sx={{
                            margin: { mobile: '70px 30px', laptop: '30px' },
                            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        }}>
                                {text && (
                                    <Typography
                                    className={classes.text}
                                    sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                    >
                                        {text}
                                    </Typography>
                                )}
                                {text2 && (
                                    <Typography
                                    className={classes.text}
                                    sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                    >
                                        {text2}
                                    </Typography>
                                )}
                                {text3 && (
                                    <Typography
                                    className={classes.text}
                                    sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                    >
                                        {text3}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                component="img"
                                src={imagen}
                                className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                            />
                            {imagen2 && (
                                <Box
                                    component="img"
                                    src={imagen2}
                                    className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                                />
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            zIndex: 99,
        },
        boxes: {
            backgroundColor: '#fff',

            display: 'flex',
            padding: '40px',
            width: '100%',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgBig: {
            width: '40vw',
            margin: '20px auto',
            borderRadius: '10px',
            alignItems: 'flex',
            justifyContent: 'flex',
            width: '100%',
        },
        link: {
            color: 'blue',
            fontWeight: 'bold',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        text: {
            flexGrow: 1,
            padding: '20px',
            textAlign: 'center',
            fontFamily: 'DINPro'
        },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default CamAndMicrophoneWin;
