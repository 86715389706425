import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { GrNorton, GrShield, GrShieldSecurity } from 'react-icons/gr';
import { SiMcafee } from 'react-icons/si';
import { FaShieldVirus } from 'react-icons/fa';

import Card from '../../../common/Card.jsx';

const AntivirusSwitcher = () => {
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: { mobile: '75vh', laptop: '100vh' },
                justifyContent: { mobile: 'space-around', laptop: 'space-evenly' },
            }}
        >
            {/* MAIN SECTION */}
            {/* <Toolbar /> */}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    marginTop: { mobile: '0px', laptop: '5vh' },
                    marginBottom: { mobile: '0px', laptop: '5vh' },
                    marginLeft: '108px',
                }}
            >
                <Typography
                    sx={{
                        display: 'inline',
                        fontFamily: 'DINPro-600',
                        fontSize: { mobile: '2.7rem', laptop: '47px' },
                        height: '56px',
                        letterSpacing: '-1.2px',
                        margin: { mobile: '0px', laptop: '10px 0px 5px 0px' },
                    }}
                >
                    Permisos
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%', margin: 'auto' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Avast'}
                        subtitle={'Configuraciones para Avast'}
                        icon={<GrShieldSecurity />}
                        reactIcon={true}
                        link={'/perm/antv/avast'}
                    />
                    <Card
                        title={'McAffe'}
                        subtitle={'Configuraciones para McAffe'}
                        icon={<SiMcafee />}
                        reactIcon={true}
                        link={'/perm/antv/mcaffe'}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Norton Securty'}
                        subtitle={'Configuraciones para Norton Security'}
                        icon={<GrNorton />}
                        reactIcon={true}
                        link={'/perm/antv/norton'}
                    />
                    <Card
                        title={'Kaspersky'}
                        subtitle={'Configuraciones para Kaspersky'}
                        icon={<FaShieldVirus />}
                        reactIcon={true}
                        link={'/perm/antv/kaspersky'}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Windows Defender'}
                        subtitle={'Configuraciones para Windows Defender'}
                        icon={<GrShield />}
                        reactIcon={true}
                        link={'/perm/antv/defender'}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AntivirusSwitcher;
