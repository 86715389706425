import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import av12 from '../../../assets/perm/antiVirus/av12.png';

const Norton = () => {
    const classes = useStyles();

    const norton = [
        ' Iniciar Norton.',
        'Si aparece la ventana de Mi Norton junto a Seguridad del dispositivo, hacer clic en Abrir.',
        'En la ventana principal de Norton, hacer clic en Configuración.',
        'En la ventana Configuración, hacer clic en Antivirus.',

        {
            menu: 'En Exclusiones / Riesgos de seguridad bajos, realizar una de las siguientes acciones:',
            cat: [
                'En la fila Elementos que se deben excluir del análisis, hacer clic en Configurar.',
                'En la fila Elementos que se deben excluir de la detección de Auto-Protect, Control de scripts, SONAR e Inteligencia sobre descargas, hacer clic en Configurar.',
            ],
        },

        'En la ventana que aparece, hacer clic en Agregar carpetas o Agregar archivos.',
        'Es posible asignar exclusiones a unidades locales, carpetas, grupos de archivos, archivos individuales o unidades de red. ',
        'En el cuadro de diálogo Agregar elemento, hacer clic en el icono de navegación.',
        'En el cuadro de diálogo que aparece, seleccionar el elemento que desea excluir del análisis.',
        'Al agregar carpetas, es posible especificar si desea incluir o excluir subcarpetas.',
        'Hacer clic en Aceptar.',
        'En el cuadro de diálogo Agregar elemento, hacer clic en Aceptar.',
        'En la ventana que aparece, hacer clic en Aplicar y, luego, en Aceptar.',
    ];
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff' }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    width: { mobile: '90vw', laptop: '40vw' },
                    height: { mobile: '20vh', laptop: '15.3rem' },
                    borderRadius: '10px',
                    margin: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                    WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                }}
                component="img"
                src={av12}
            />

            <Box className={classes.boxes} flexDirection="column">
                <ol>
                    {norton.map((e) => {
                        return (
                            <Box>
                                {e.menu ? (
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'DINPro',
                                                fontSize: { mobile: '2rem', laptop: '1rem' },
                                                margin: { mobile: ' 2rem auto 0px 0px', laptop: '15px' },
                                            }}
                                        >
                                            <li> {e.menu} </li>
                                            <ul>
                                                {e.cat.map((submenu) => (
                                                    <li className={classes.subMenu}>{submenu}</li>
                                                ))}
                                            </ul>
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography
                                        sx={{
                                            fontFamily: 'DINPro',
                                            fontSize: { mobile: '2rem', laptop: '1rem' },
                                            margin: { mobile: ' 2rem auto 0px 0px', laptop: '15px' },
                                        }}
                                    >
                                        <li>{e}</li>
                                    </Typography>
                                )}
                            </Box>
                        );
                    })}
                </ol>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgTitle: { borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { maxWidth: '80vw', flex: 1, padding: '20px', textAlign: 'center' },
        subMenu: { padding: '10px' },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default Norton;
