import React, { forwardRef } from 'react';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';

const MenuItemComponent = (props) => {
    const { className, onClick, link, children } = props;

    if (!link || typeof link !== 'string') {
        return <ListItem button className={className} children={children} onClick={onClick} />;
    }

    return (
        <ListItem
            button
            className={className}
            children={children}
            component={forwardRef((props, ref) => {
                return <NavLink exact {...props} innerref={ref} />;
            })}
            to={link}
        />
    );
};

export default MenuItemComponent;
