import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import MenuItemComponent from './MenuItemComponent.jsx';

const MenuItem = (props) => {
    const { name, link, Icon, items = [], type, drawerWidth } = props;
    const [open, setOpen] = React.useState(false);
    // const [visibility, setVisibility] = React.useState()

    const classes = useStyles();

    const isExpandable = items && items.length > 0;

    function handleClick() {
        setOpen(!open);
    }

    const MenuItemRoot = (
        <MenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
            {!!Icon && (
                <IconButton
                    className={classes.menuItemIcon}
                    sx={{
                        // backgroundImage: props.home && 'linear-gradient(225deg, #009fff 0%, #29f39b 100%)',
                        borderRadius: '20px',
                        margin: props.mobile && '15px 0px',
                        height: props.mobile && '70px',
                        width: props.mobile && '70px',
                        fontSize: {
                            tablet: `${type === 1 ? '3rem' : type === 2 ? '3rem' : '3rem'}`,
                            laptop: `${type === 1 ? '1.6rem' : type === 2 ? '1.2rem' : '0.8rem'}`,
                        },
                    }}
                >
                    {Icon}
                </IconButton>
            )}

            <ListItemText
                primary={name}
                //  inset={!Icon}
                primaryTypographyProps={{
                    display: drawerWidth === 300 || props.mobile ? 'initial' : 'none',
                    padding: { mobile: '20px', tablet: '30px', laptop: '0px' },
                    fontSize: { mobile: '33px', laptop: '18px' },
                    transition: '0.5s all ease-in-out',
                    marginLeft: '17px',
                }}
            />

            {/* 
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />} */}
        </MenuItemComponent>
    );

    const MenuItemChildren = (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding sx={{ marginLeft: `30px` }}>
                {items.map((item, index) => {
                    return <MenuItem {...item} key={index} />;
                })}
            </List>
        </Collapse>
    );

    return (
        <>
            {MenuItemRoot}
            {/* {isExpandable && MenuItemChildren} */}
        </>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        menuItem: {
            width: '100%',
            justifyContent: 'center',
            borderRadius: '10px',
            '&.active': {
                background: '#e1f7f8',
            },
        },
        menuItemIcon: {
            color: '#050505',
        },
    })
);

export default MenuItem;
