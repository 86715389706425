import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import EmpCircuito from '../../common/EmpCircuito.jsx';
import EmpBody from '../../common/EmpBody';
import NewEmp from './NewEmp.jsx';

const DocSys = ({ uni }) => {
    const classes = useStyles();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <NewEmp></NewEmp>
            {/* <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.5rem', laptop: '1.2rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#14b0ee',
                    fontFamily: 'DINPro'
                }}
            >
                Descargar Klarway en el celular y seguir los siguientes pasos para completar el registro.
            </Typography>

            <EmpCircuito uni={uni} />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { mobile: 'center', laptop: 'center' },
                    margin: { mobile: '60px auto', laptop: '60px 0px' },
                    width: '23vw',
                }}
            >
                {/* <Box
                    sx={{
                        display: { mobile: 'none', laptop: 'initial' },
                        margin: { mobile: '0px auto', laptop: '0px' },
                    }}
                >
                    <SvgIcon component={ArrowDownwardIcon} className={classes.icon} />
                </Box> */}
                {/* <SvgIcon component={ArrowDownwardIcon} className={classes.icon} /> */}
            {/* </Box> */}

            {/* TERCERA SECCION DE IMAGENES */}

             {/* <EmpBody type={'sys'} />  */}
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        icon: { fontSize: '70px', color: '#9bf7d3', fontSize: '7vh' },
    })
);

export default DocSys;
