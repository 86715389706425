import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import desdesc1 from '../../assets/perm/desDesc/desdesc1.png';
import desdesc2 from '../../assets/perm/desDesc/desdesc2.png';
import desdesc3 from '../../assets/perm/desDesc/desdesc3.png';
import desdesc4 from '../../assets/perm/desDesc/desdesc4.png';
import desdesc5 from '../../assets/perm/desDesc/desdesc5.png';
import desdesc6 from '../../assets/perm/desDesc/desdesc6.png';

const DesDescMac = (props) => {
    const classes = useStyles();

    const desDescMac = [
        {
            text: 'Abrir el menú de Apple. Haciendo clic en el logo de Apple en la esquina superior izquierda de la pantalla aparecerá un menú desplegable.',
            imagen: desdesc1,
        },
        {
            text: 'Hacer clic en Preferencias del sistema. Esta opción está casi al principio del menú desplegable.',
            imagen: desdesc2,
        },
        {
            text: 'Hacer clic en Seguridad y privacidad. Se encuentra en la parte superior de la ventana de Preferencias del sistema.',
            imagen: desdesc3,
        },
        {
            text: ' Hacer clic en el icono del candado. Se encuentra en la esquina inferior izquierda de la ventana.',
            imagen: desdesc4,
        },
        {
            text: 'Escribir tu contraseña y hacer clic en Desbloquear. Esto permitirá editar los ajustes en este menú.',
            imagen: desdesc5,
        },
        {
            text: 'Hacer clic en Abrir de cualquier manera. Se encuentra a lado del nombre de tu archivo.',
            imagen: desdesc6,
        },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Permisos de Instalación (Mac)
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {desDescMac.map(({ text, imagen }) => {
                    return (
                        <Box className={classes.boxes} sx={{ flexDirection: { mobile: 'column', laptop: 'row' } }}>
                            {text && (
                                <Typography
                                    className={classes.text}
                                    sx={{
                                        fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                        margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px' },
                                    }}
                                >
                                    {text}
                                </Typography>
                            )}

                            <Box
                                component="img"
                                src={imagen}
                                className={classes.imagen}
                                sx={{
                                    objectFit: { mobile: 'fill', laptop: 'contain' },
                                    width: { mobile: '100%', laptop: '50%' },
                                }}
                            />
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            zIndex: 99,
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px', boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)' },
        imgBig: {
            width: '40vw',
            margin: '20px auto',
            borderRadius: '10px',
            width: '100%',
        },
        link: {
            color: 'blue',
            fontWeight: 'bold',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        text: {
            // maxWidth: '90vw',
            flexGrow: 1,
            padding: '20px',
            textAlign: 'center',
        },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default DesDescMac;
