import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import terminos from '../../assets/terminos.png'
import regkw from '../../assets/regkw.png'
import registro1 from '../../assets/registro1.png'
import regmoodle from '../../assets/regmoodle.png'
import reglondres from '../../assets/reglondres.png'
import regcanvas from '../../assets/regcanvas.png'
import pucp2 from '../../assets/pucp2.png'
import udemm2 from '../../assets/udemm2.png'



import teclab from '../../assets/teclab.png'
import ippregistro from '../../assets/ippregistro.png'
import londres from '../../assets/londres.png'
import mariamercante from '../../assets/mariamercante.png'
import pucp from '../../assets/pucp.png'
import sanbuenaventura from '../../assets/sanbuenaventura.png'
import edreg from '../../assets/edreg.png'
import NewEmp from './NewEmp';





import EmpDesktop from '../../common/EmpDesktop.jsx';


const DocDesktop = ({ uni }) => {
    const classes = useStyles();

    const universidad = uni === 'UES21' ? 'Universidad Siglo 21' : uni;
    const unis = ['UES21', 'TECLAB', 'IPP', 'CANVAS', 'MOODLE','Universidad del Bosque','Universidad de San Buenaventura','Universidad de la Mariana Mercante','Universidad PUCP','Universidad ONMEX','LONDRES', 'OTROS'];

    const instituto = uni === 'LONDRES' ? londres : uni === 'TECLAB' ? teclab : uni === 'IPP' ? ippregistro : uni === 'IPP' ? ippregistro : uni === 'Universidad de la Mariana Mercante' ? mariamercante : uni === 'Universidad PUCP' ? pucp : uni === 'Universidad de San Buenaventura' ? sanbuenaventura : null;
    const instituto1 = uni === 'LONDRES' ? reglondres : uni === 'MOODLE' ? regmoodle : uni === 'CANVAS' ? regcanvas : uni === 'Universidad del Bosque' ? regmoodle : uni === 'Universidad PUCP' ? pucp2 : uni === 'Universidad de San Buenaventura' ? regmoodle : uni === 'Universidad de la Mariana Mercante' ? udemm2 : edreg;

    // src={type === 'lms' ? cnv4 : instituto}

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.5rem', laptop: '1.2rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#14b0ee',
                    fontFamily: 'DINPro'
                }}
            >
            	Abre la app de Klarway en tu computadora y sigue los siguientes pasos para completar el registro !
            </Typography>
            <NewEmp></NewEmp>
            
            {/* <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	1.	Accede con tus datos de alumno, como habitualmente lo haces.
            </Typography>
            <Box
                        component="img"
                        src={unis != 'a' ? instituto : null}
                        className={classes.imgBig} sx={{ width: { laptop: '30vw' }, }}

                    />
                    </Box>

            <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
            <Typography
            
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	2.	" Dirígete a la parte superior derecha a "Perfil" y selecciona "Editar registro".
            </Typography>
            <Box component="img" src={unis != 'a' ? instituto1 : edreg} className={classes.imgBig} sx={{ width: { laptop: '30vw' } }} />
            </Box>

            <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
            <Typography
            
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	3.	A continuación, aparecerán los “Términos y Condiciones” y “Políticas de Privacidad”, y deberás aceptarlos, para poder continuar.
            </Typography>
            <Box component="img" src={terminos} className={classes.imgBig} sx={{ width: { laptop: '30vw' } }} />
            </Box>
            
            <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	4.	Al abrirse la ventana, el registro aparecerá como incompleto. En este caso, deberás seguir los siguientes pasos:
            </Typography>
            <Box component="img" src={registro1} className={classes.imgBig} sx={{ width: { laptop: '30vw' } }} />
            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	De no abrirse dicha ventana automáticamente, luego de loguearte, deberás clickear el
ícono de registro para realizar el paso previo en la home del sitio.
            </Typography>
            <Box component="img" src={regkw} className={classes.imgBig} sx={{ width: { laptop: '30vw' } }} />
            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	Otra manera de acceder al registro es accediendo a un curso en particular.
            </Typography>
            </Box>
            
            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
            	5.	Completar la información solicitada en la ventana que se abrirá inmediatamente.
            </Typography>

            <EmpDesktop uni={uni} />

          

            {/* TERCERA SECCION DE IMAGENES */}

            
        </Box> 
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        icon: { fontSize: '70px', color: '#9bf7d3', fontSize: '7vh' },
    })
);

export default DocDesktop;
