import { FcCheckmark } from 'react-icons/fc';

import { ReactComponent as Download } from '../assets/sidebar/download.svg';
import { ReactComponent as Exam } from '../assets/sidebar/exam.svg';
import { ReactComponent as Help } from '../assets/sidebar/help.svg';
import { ReactComponent as Home } from '../assets/sidebar/home.svg';
import { ReactComponent as Padrons } from '../assets/sidebar/padrons.svg';
import { ReactComponent as Permissions } from '../assets/sidebar/permissions.svg';
import { ReactComponent as Requeriments } from '../assets/sidebar/requeriments.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export const data = [
    {
        name: 'Home',
        Icon: <Home />,
        link: '/',
        type: 2,
        home: true,
    },
    {
        name: 'Instalación',
        // items: [
        //     {
        //         name: 'Windows',
        //         items: [
        //             { name: 'Descargar Klarway', link: '/inst/win/1', Icon: <VscCircleFilled /> },
        //             { name: 'Instalar Klarway', link: '/inst/win/2', Icon: <VscCircleFilled /> },
        //         ],
        //         Icon: <BsWindows />,
        //         type: 2,
        //     },
        //     {
        //         name: 'MacOS',
        //         items: [
        //             { name: 'Descargar Klarway', link: '/inst/mac/1', Icon: <VscCircleFilled /> },
        //             { name: 'Instalar Klarway', link: '/inst/mac/2', Icon: <VscCircleFilled /> },
        //         ],
        //         Icon: <BsApple />,
        //         type: 2,
        //     },
        // ],
        link: '/inst',
        Icon: <Download />,
        type: 2,
    },
    {
        name: 'Registro',
        Icon: <Padrons />,
        link: '/emp',
        type: 2,
    },

    {
        name: 'Examen',
        Icon: <Exam />,
        link: '/exam',
        type: 2,
    },
    { name: 'Requisitos', link: '/req', Icon: <Requeriments />, type: 1 },

    {
        name: 'Permisos',
        // items: [
        //     { name: 'Permisos de instalación (Mac) ', link: '/perm/dev', Icon: <MdOutlineDeveloperMode />, type: 2 },

        //     {
        //         name: 'Cámara y Micrófono',
        //         items: [
        //             {
        //                 name: 'Windows',
        //                 link: '/perm/multi/w',
        //                 Icon: <BsWindows />,
        //                 type: 2,
        //             },
        //             {
        //                 name: 'MacOS',
        //                 link: '/perm/multi/m',
        //                 Icon: <BsApple />,
        //                 type: 2,
        //             },
        //         ],
        //         Icon: <MdPermCameraMic />,
        //         type: 2,
        //     },
        //     {
        //         name: 'Antivirus',
        //         items: [
        //             {
        //                 name: 'Avast',
        //                 link: '/perm/antv/avast',
        //                 Icon: <GrShieldSecurity />,
        //                 type: 2,
        //             },

        //             {
        //                 name: 'McAffe',
        //                 link: '/perm/antv/mcaffe',
        //                 Icon: <SiMcafee />,
        //                 type: 2,
        //             },
        //             {
        //                 name: 'Norton Securty',
        //                 link: '/perm/antv/norton',
        //                 Icon: <GrNorton />,
        //                 type: 2,
        //             },

        //             {
        //                 name: 'Kaspersky',
        //                 link: '/perm/antv/kaspersky',
        //                 Icon: <FaShieldVirus />,
        //                 type: 2,
        //             },
        //             {
        //                 name: 'Windows Defender',
        //                 link: '/perm/antv/defender',
        //                 Icon: <GrShield />,
        //                 type: 2,
        //             },
        //         ],
        //         Icon: <Permissions />,
        //         type: 2,
        //     },
        //     {
        //         name: 'Cerrar Procesos Abiertos',
        //         items: [
        //             {
        //                 name: 'Windows',
        //                 link: '/perm/proc/w',
        //                 Icon: <BsWindows />,
        //                 type: 2,
        //             },
        //             {
        //                 name: 'MacOS',
        //                 link: '/perm/proc/m',
        //                 Icon: <BsApple />,
        //                 type: 2,
        //             },
        //         ],
        //         // link: '/perm/proc',
        //         Icon: <MdPermCameraMic />,
        //         type: 2,
        //     },
        // ],
        link: '/perm',
        Icon: <Permissions />,
        type: 2,
    },
    { name: 'Más info', Icon: <Help />, link: '/help', type: 2 },
    { name: 'Preguntas frecuentes', Icon: <HelpOutlineIcon style={{width:'37', height:'37', color:"#404040"}} />, link: '/pf', type: 2 },
];

export const requirements = [
    {
        name: 'Windows',
        os: 'Windows 10',
        proc: 'Intel Core i3',
        arq: '64 bits',
        ram: '4 Gb',
        mem: '1 Gb',
        cam: <FcCheckmark />,
        mic: <FcCheckmark />,
        inet: <FcCheckmark />,
        bat: 'Cargada al 100% o conectada a la red eléctrica',
    },

    {
        name: 'MacOS',
        os: 'MacOS Monterrey 12.6.3 o superior',
        proc: 'Intel Core i3',
        arq: '64 bits',
        ram: '4 Gb',
        mem: '1 Gb',
        cam: <FcCheckmark />,
        mic: <FcCheckmark />,
        inet: <FcCheckmark />,
        bat: 'Cargada al 100% o conectada a la red eléctrica',
    },

    // {
    //     name: 'Android',
    //     os: 'Desde Lollipop 5.0',
    //     mem: '54 Mb',
    //     cam: <FcCheckmark />,
    //     inet: <FcCheckmark />,
    // },
    // ,
    // {
    //     name: 'iOS',
    //     os: 'Desde iOS 14.2',
    //     mem: '54 Mb',
    //     cam: <FcCheckmark />,
    //     inet: <FcCheckmark />,
    // },
];
