import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import av10 from '../../../assets/perm/antiVirus/av10.png';

const Mcaffe = () => {
    const classes = useStyles();

    const mcaffe = [
        'Hacer clic en Excluded Files (Archivos excluidos).',
        'Hacer clic en Real-Time Scanning (Análisis en tiempo real).',
        'Hacer clic en PC Security (en Windows) o Mac Security (en macOS).',
        'Hacer clic en Add file (Agregar archivo).',
        'Localizar y seleccionar el archivo que deseas excluir de los análisis.',
        'Repetir el proceso para excluir de los análisis otros archivos.',
        'El Análisis en tiempo real no analizará los archivos seleccionados después de llevar a cabo estos pasos.',
    ];
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    width: { mobile: '90vw', laptop: '40vw' },
                    height: { mobile: '20vh', laptop: '15.3rem' },
                    borderRadius: '10px',
                    margin: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                    WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                }}
                component="img"
                src={av10}
            />
            <Box className={classes.boxes}>
                <ol>
                    {mcaffe.map((e) => {
                        return (
                            <Typography
                                sx={{
                                    fontFamily: 'DINPro',
                                    fontSize: { mobile: '2.3rem', laptop: '1rem' },
                                    margin: { mobile: ' 2rem auto 0px 0px', laptop: '15px' },
                                }}
                            >
                                <li>{e}</li>
                            </Typography>
                        );
                    })}
                </ol>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgTitle: { borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { maxWidth: '80vw', flex: 1, padding: '20px', textAlign: 'center', fontFamily: 'DINPro' },
        subMenu: { padding: '10px' },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default Mcaffe;
