import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import m1 from '../../assets/perm/virtualCams/M1.png';
import w2 from '../../assets/perm/virtualCams/w2.png';
import w3 from '../../assets/perm/virtualCams/w3.png';
import w4 from '../../assets/perm/virtualCams/w4.png';
import w5 from '../../assets/perm/virtualCams/w5.png';
import w6 from '../../assets/perm/virtualCams/w6.png';

import cvm1 from '../../assets/perm/virtualCams/cvm1.png';
import cvm2 from '../../assets/perm/virtualCams/cvm2.png';

const VirtualCams = ({ type }) => {
    const classes = useStyles();

    console.log(type);

    const pasosMac = [
        { text: 'En el Finder, localiza la pestaña “Ir” y selecciona “Aplicaciones”.', imagen: cvm1 },
        { text: 'Busca la cámara virtual y transfiere al basurero.', imagen: cvm2 },


        // {
        //     text: 'Buscar la app en el Finder, en este caso la cámara virtual especificada en el mensaje de Klarway.',
        //     text2: 'Arrastrar la app a la papelera o seleccionar la app y luego, Archivo > Trasladar a la papelera.',
        //     imagen: m1,
        //     column: true,
        // },
    ];

    const pasosWin = [
        { text: 'En inicio selecciona "Configuración".', imagen: w2 },
        { text: 'Selecciona "APLICACIONES"', imagen: w3 },
        { text: 'Selecciona APLICACIONES Y CARACTERÍSTICAS', imagen: w4 },
        {
            text: 'Ingresa el nombre de la cámara virtual a desinstalar. El nombre de la misma, se encontrará en el cartel que muestra Klarway.',
            text2: 'Selecciona la app y hacer click en DESINSTALAR',
            imagen: w5,
        },
        { text: 'Se deberá confirmar la DESINSTALACIÓN de la misma.', imagen: w6 },
    ];

    const pasos = type === 'm' ? pasosMac : pasosWin;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Desinstalar cámaras virtuales ({type === 'm' ? 'Mac' : 'Windows'})
            </Typography>
            <Divider />
            <Box
                sx={{
                    margin: { mobile: '70px 30px', laptop: '30px' },
                    flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                }}
            >
                {pasos.map(({ text, text2, imagen, imagen2, column }) => {
                    const direction = column ? 'column' : 'row';
                    return (
                        <Box
                        className={classes.boxes}
                        sx={{
                            margin: { mobile: '70px 30px', laptop: '30px' },
                            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        }}
                        >
                            <Box sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}>
                                {text && (
                                    <Typography
                                    className={classes.text}
                                    sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                    >
                                        <b>{text}</b>
                                    </Typography>
                                )}
                                {text2 && (
                                    <Typography
                                    className={classes.text}
                                    sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                    >
                                        <b> {text2}</b>
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                component="img"
                                src={imagen}
                                className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                            />
                            {imagen2 && (
                                <Box
                                    component="img"
                                    src={imagen2}
                                    className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                                />
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            zIndex: 99,
        },
        boxes: {
            backgroundColor: '#fff',

            display: 'flex',
            padding: '40px',
            width: '100%',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgBig: {
            width: '40vw',
            margin: '20px auto',
            borderRadius: '10px',
            alignItems: 'flex',
            justifyContent: 'flex',
            width: '100%',
        },
        link: {
            color: 'blue',
            fontWeight: 'bold',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        text: {
            flexGrow: 1,
            padding: '20px',
            textAlign: 'center',
            fontFamily: 'DINPro'
        },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default VirtualCams;
