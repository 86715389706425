import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import cymW1 from '../../assets/perm/camYMic/cymW1.png';
import cymW2 from '../../assets/perm/camYMic/cymW2.png';
import cymW3 from '../../assets/perm/camYMic/cymW3.png';
import cymW4 from '../../assets/perm/camYMic/cymW4.png';

import cymM6 from '../../assets/perm/camYMic/cymM6.png';
import cymM7 from '../../assets/perm/camYMic/cymM7.png';
import cymM8 from '../../assets/perm/camYMic/cymM8.png';
import videoDesk from '../../assets/perm/camYMic/videoDesk.png';
import remove from '../../assets/perm/camYMic/remove.png';
import permiso1 from '../../assets/perm/camYMic/permiso1.png';
import permiso2 from '../../assets/perm/camYMic/permiso2.png';
import permiso3 from '../../assets/perm/camYMic/permiso3.png';
import permiso4 from '../../assets/perm/camYMic/permiso4.png';
import permiso5 from '../../assets/perm/camYMic/permiso5.png';
import permiso6 from '../../assets/perm/camYMic/permiso6.png';
import permiso7 from '../../assets/perm/camYMic/permiso7.png';
import permiso8 from '../../assets/perm/camYMic/permiso8.png';
import permiso9 from '../../assets/perm/camYMic/permiso9.png';
import camymicmac from '../../assets/perm/camYMic/camymicmac.png';



const CamAndMicrophone = ({ type }) => {
    const classes = useStyles();

    const pasosMac = [
        {
            text: 'La primera vez que se inicia Klarway, se solicitarán permisos para el uso de cámara, micrófono y grabación de pantalla.',
            text2: 'Cámara y micrófono: Seleccionar "OK".',
            imagen: cymM6,
            imagen2: cymM7,
            column: true,
        },

        {
            text2: 'El permiso de grabación de pantalla requiere abrir las preferencias del sistema y seguir algunos pasos:',
            imagen: cymM8,
        },
        {
            text: (
                <>
                    <b>Acceder a privacidad, grabación de pantalla y activar Klarway.</b>
                </>
            ),
            imagen: videoDesk,
        },
        {
            text: 'Si luego de otorgar el permiso de grabación a Klarway, el sistema continúa solicitándolo, acceder nuevamente a la pantalla de Seguridad y Privacidad. En el recuadro de permisos, seleccionar Klarway y con el signo menos eliminarla. Posteriormente, agregar nuevamente Klarway, presionando el signo más y seleccionando la app de Klarway en la ventana que se abrirá. ',
            text2: (
                <>
                    <b>Para realizar los cambios es necesario abrir el candado con la clave de MAC.</b>
                </>
            ),
            imagen: remove,
            column: true,
        },
    ];

    const pasosWin = [
        { text: ' "Configuración".', imagen: cymW1 },
        { text: 'Ingresar en "Privacidad".', imagen: cymW2 },
        { text: 'Seleccionar la opción "Micrófono" y controlar que esté activado.', imagen: cymW3 },
        { text: 'Seleccionar la opción "Cámara" y controlar que esté activado.', imagen: cymW4 },
    ];

    const pasos = type === 'm' ? pasosMac : pasosWin;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Permisos Cámara y Micrófono ({type === 'm' ? 'Mac' : 'Windows'})
            </Typography>
            <Divider />

            <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Al abrir Klarway, se solicitarán algunos permisos por única vez.
                    </Typography>
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Primero se solicitará el permiso de la cámara, el cual se deberá aceptar para poder
avanzar.
                    </Typography>
                    <Box
                        component="img"
                        src={permiso1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                       className={classes.text}
                       sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Se solicitará los permisos del micrófono, se deberán aceptar para poder avanzar.
                    </Typography>
                    <Box
                        component="img"
                        src={permiso2}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Se solicitará el permiso para grabación de pantalla, el cual debe ser activado en las
configuración del sistema. Es recomendable seleccionar en el cuadro de dialogo “Abrir
configuración de sistemas” para poder avanzar.
                    </Typography>
                    <Box
                        component="img"
                        src={permiso3}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Una vez en ella, dirigirse a grabación de pantalla
                    </Typography>
                    <Box
                        component="img"
                        src={permiso4}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Dentro de la pestaña de grabación de pantalla, habilitar el permiso a Klarway.
                    </Typography>
                    <Box
                        component="img"
                        src={permiso5}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Se solicitará la contraseña para realizar el cambio.
                    </Typography>
                    <Box
                        component="img"
                        src={permiso6}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                    <Box
                        component="img"
                        src={permiso7}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '19vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Una vez ingresada la contraseña, aparecerá el siguiente cartel que recomienda cerrar
y abrir nuevamente Klarway.
                    </Typography>
                    <Box
                        component="img"
                        src={permiso8}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Si se siguieron los pasos correctamente, deberás volver a seleccionar tu institución e
ingresar a la página de la misma.
                    </Typography>
                    <Box
                        component="img"
                        src={camymicmac}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>




            {/* <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                {pasos.map(({ text, text2, text3, imagen, imagen2, column }) => {
                    const direction = column ? 'column' : 'row';
                    return (
                        <Box className={classes.boxes} sx={{ flexDirection: { mobile: 'column', laptop: direction } }}>
                            <Box sx={{ flexDirection: { mobile: 'column', laptop: direction } }}>
                                {text && (
                                    <Typography
                                        className={classes.text}
                                        sx={{
                                            fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                            margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px' },
                                        }}
                                    >
                                        {text}
                                    </Typography>
                                )}
                                {text2 && (
                                    <Typography
                                        className={classes.text}
                                        sx={{
                                            fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                            margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px' },
                                        }}
                                    >
                                        {text2}
                                    </Typography>
                                )}
                                {text3 && (
                                    <Typography
                                        className={classes.text}
                                        sx={{
                                            fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                            margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px' },
                                        }}
                                    >
                                        {text3}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                component="img"
                                src={imagen}
                                className={classes.imagen}
                                sx={{
                                    objectFit: { mobile: 'fill', laptop: 'contain' },
                                    width: { mobile: '100%', laptop: '50%' },
                                }}
                            />
                            {imagen2 && (
                                <Box
                                    component="img"
                                    src={imagen2}
                                    className={classes.imagen}
                                    sx={{
                                        objectFit: { mobile: 'fill', laptop: 'contain' },
                                        width: { mobile: '100%', laptop: '50%' },
                                    }}
                                />
                            )}
                        </Box>
                    );
                })}
            </Box> */}
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            zIndex: 99,
        },
        boxes: {
            backgroundColor: '#fff',

            display: 'flex',
            padding: '40px',
            width: '100%',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgBig: {
            width: '40vw',
            margin: '20px auto',
            borderRadius: '10px',
            alignItems: 'flex',
            justifyContent: 'flex',
            width: '100%',
        },
        link: {
            color: 'blue',
            fontWeight: 'bold',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        text: {
            flexGrow: 1,
            padding: '20px',
            textAlign: 'center',
            fontFamily: 'DINPro'
        },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default CamAndMicrophone;
