import * as React from 'react';
import ReactDOM from 'react-dom';
import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App.jsx';

const defaultTheme = createTheme();
const theme = createTheme({
    typography: {
        fontFamily: ['DINPro-400', 'DINPro-500', 'DINPro-600', 'DINPro-700', 'DINPro-800', 'DINPro-900'].join(','),
        allVariants: {
            color: '#404040',
        },
    },
    breakpoints: {
        values: {
            ...defaultTheme.breakpoints.values,
            mobile: 0,
            tablet: 600,
            netbook: 900,
            laptop: 1130,
            desktop: 1500,
        },
    },
});

ReactDOM.render(
    <BrowserRouter>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </BrowserRouter>,
    document.querySelector('#root')
);
