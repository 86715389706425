import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Help = () => {
    const classes = useStyles();

    const textos = [
        {
            title: 'Conociendo Klarway',
            items: [
                <>
                    Tomamos más de <b>1.500.000</b> <b>exámenes</b>.
                </>,
                <>
                    Logramos que <b>90.000 alumnos </b>realicen instancias evaluativas de manera segura y virtual.
                </>,
                'Klarway es una aplicación como otras que se instalan en la PC.',
                <>
                    <b>El alumno decide cuándo ejecutar</b> la app, cerrarla e incluso desinstalarla.
                </>,
                <>
                    Klarway <b>consume batería</b> porque usa cámara y micrófono mientras graba la pantalla, tal como
                    ocurre en una videollamada.
                </>,
                <>
                    Klarway <b>no puede </b>romper dispositivos ni afectar archivos o sesiones.
                </>,
                <>
                    <b>Klarway detecta incidencias</b> o acciones que puedan ocurrir durante el exámen con el fin de
                    custodiar la instancia de evaluación.
                </>,
                <>
                    {' '}
                    <b>Los datos</b> solicitados <b> permiten reconocer al estudiante</b> que realiza el examen y
                    asegurar su identidad.
                </>,
            ],
        },
        {
            title: 'Consejos técnicos ',
            items: [
                <>
                    <b>Desinstala las cámaras virtuales</b> que tengas en tu computadora.
                </>,
                <>
                    <b>Realiza una limpieza en tu ordenador</b>, es importante no contar con programas que puedan
                    afectar el correcto funcionamiento.
                </>,
                <>
                    Si tienes una <b>máquina virtual</b>, asegúrate que esté cerrada al usar Klarway.
                </>,
                <>
                    Necesitas tener <b>permiso para instalar apps</b> en tu computadora.
                </>,
            ],
        },
        {
            title: 'Tips para el examen ',
            items: [
                <>
                    Busca un <b>espacio cómodo y silencioso.</b>
                </>,
                <>
                    En lo posible, realiza la evaluación con un <b>fondo liso detrás de ti.</b>
                </>,
                <>
                    Asegúrate de que <b>tu rostro se vea bien</b> dentro de la pantalla, sin cortes ni luces.
                </>,
                <>
                    El<b> espacio debe estar iluminado</b>, procurando que la imagen sea nítida.
                </>,
                <>
                    Recuerda <b>conectar tu PC a una red eléctrica</b>, consumirá batería al tener la cámara y el
                    micrófono en ejecución.
                </>,
                <>
                    Si realizas el examen en un lugar que compartes con otras personas, solicita que{' '}
                    <b>no usen internet durante ese momento</b> para lograr una mejor experiencia.
                </>,
            ],
        },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px' }}>
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'DINPro-500',

                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Tener en cuenta
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {textos.map(({ title, items }) => {
                    return (
                        <Box className={classes.boxes} sx={{ flexDirection: 'column' }}>
                            <Typography
                                className={classes.text}
                                sx={{
                                    fontFamily: 'DINPro-800',
                                    fontSize: { mobile: '1.8rem', laptop: '1.2rem' },
                                    margin: { mobile: ' 1rem auto 0px 0px', laptop: '0px' },
                                }}
                            >
                                {title}
                            </Typography>

                            <ul>
                                {items.map((text) => (
                                    <Typography
                                        className={classes.text}
                                        sx={{
                                            fontFamily: 'DINPro-500',
                                            fontSize: { mobile: '1.8rem', laptop: '1.05rem' },
                                            margin: { mobile: ' 1rem auto 0px 0px', laptop: '0px' },
                                        }}
                                    >
                                        <li> {text} </li>
                                    </Typography>
                                ))}
                            </ul>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            zIndex: 99,
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            width: '85%',
            margin: '30px',
            justifyContent: 'center',
            //alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgBig: {
            width: '40vw',
            margin: '20px auto',
            borderRadius: '10px',
            width: '100%',
        },
        link: {
            color: 'blue',
            fontWeight: 'bold',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        text: {
            // maxWidth: '90vw',
            flexGrow: 1,
            padding: '5px',
            fontFamily: 'DINPro'
        },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default Help;
