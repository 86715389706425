import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import Card from '../common/Card.jsx';
import Search from './Search.jsx';
import IconEmp from '../assets/home/iconEmp.svg';
import IconExam from '../assets/home/iconExam.svg';
import IconInst from '../assets/home/iconInst.svg';

import BotonInst from '../assets/home/botonInstalacion.svg';
import BotonEmp from '../assets/home/botonEmpadronamiento.svg';
import BotonExam from '../assets/home/botonExamen.svg';

import download from '../assets/icons/download.svg';
import exam from '../assets/icons/exam.svg';
import help from '../assets/icons/help.svg';
import padrons from '../assets/icons/padrons.svg';
import permissions from '../assets/icons/permissions.svg';
import request from '../assets/icons/request.svg';

function Principal() {
    const classes = useStyles();

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: { mobile: '80vh', laptop: '100vh' },
                justifyContent: { mobile: 'space-around', laptop: 'center' },
            }}
        >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />

            {/* MAIN SECTION */}
            {/* <Toolbar /> */}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    marginLeft: '108px',
                    marginTop: { mobile: '8vw', laptop: '15vh' },
                }}
            >
                <Typography
                    sx={{
                        letterSpacing: '-2px',
                        fontFamily: 'DINPro-900',
                        maxWidth: { mobile: '65vw', laptop: '100vw' },
                        lineHeight: 0.92,
                        fontSize: { mobile: '5rem', laptop: '50px' },
                    }}
                >
                    Bienvenido a la página
                </Typography>
                <Typography
                    sx={{
                        letterSpacing: '-2px',
                        fontFamily: 'DINPro-900',
                        maxWidth: { mobile: '65vw', laptop: '100vw' },
                        lineHeight: 0.92,
                        fontSize: { mobile: '5rem', laptop: '50px' },
                    }}
                >
                    de <span className={classes.color}> ayuda de Klarway </span>
                </Typography>

                {/* 
            <Search /> */}
                {/* <Typography
                    sx={{
                        display: 'inline',
                        fontFamily: 'DINPro-400',
                        fontSize: { mobile: '2.7rem', laptop: '35px' },
                        height: '56px',
                        letterSpacing: '-2.28px',
                        margin: { mobile: '0 0 20px 0', laptop: '10px 0px 5px 0px' },
                    }}
                >
                    ¿Necesitas ayuda?
                </Typography> */}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%', margin: 'auto' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Descargar e instalar Klarway'}
                        subtitle={'Windows - IOS'}
                        icon={download}
                        link={'/inst'}
                    />
                    <Card title={'Registrarse'} subtitle={'Nuevos Alumnos'} icon={padrons} link={'/emp'} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Realizar exámenes'}
                        subtitle={'Paso a paso para el exámen online'}
                        icon={exam}
                        link={'/exam'}
                    />
                    <Card
                        title={'Requisitos tecnológicos'}
                        subtitle={'Para la instalación y uso'}
                        icon={request}
                        link={'/req'}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card title={'Otorgar permisos'} subtitle={'Para usar Klarway'} icon={permissions} link={'/perm'} />
                    <Card title={'Más info'} subtitle={'A tener en cuenta'} icon={help} link={'/help'} />
                </Box>
            </Box>

            {/* <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '81%',
                    marginTop: { mobile: '0px', laptop: '30px' },
                    marginLeft: '108px',
                }}
            >
                <Typography
                    sx={{
                        display: 'inline',
                        fontFamily: 'DINPro-400',
                        fontSize: { mobile: '2.3rem', laptop: '20px' },
                        letterSpacing: '-1.3px',
                    }}
                >
                    Siempre recuerda <b> seguir los pasos indicados por tu institución.</b>
                </Typography>
                <Typography
                    sx={{
                        display: 'inline',
                        fontFamily: 'DINPro-400',
                        fontSize: { mobile: '2.3rem', laptop: '20px' },
                        margin: { mobile: '30px 0px', laptop: '0px' },
                        letterSpacing: '-1.3px',
                    }}
                >
                    <b>En caso de necesitar ayuda,</b> encontrá mas información en el menú de la izquierda.
                </Typography>

                <Box
                    type="text"
                    sx={{
                        color: '#4ec7ff',
                        fontFamily: 'DINPro-700',
                        fontSize: { mobile: '2.8rem', laptop: '25px' },
                        letterSpacing: '-1.3px',
                        marginTop: { mobile: '45px', laptop: '15px' },
                    }}
                >
                    ¡Éxito en tus instancias evaluativas!
                </Box> 
            </Box>*/}
        </Box>
    );
}

const useStyles = makeStyles((theme) =>
    createStyles({
        box: {
            fontWeight: 'bold',
            color: '#0cbcd8',
            height: '90px',
            border: '1px solid',
            borderColor: '#2bf29c',
            borderRadius: '20px',
            backgroundColor: '#fff',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transition: 'all 0.4s ease-in-out',
            letterSpacing: '2px',
            '&:hover': {
                fontSize: '1.3rem',
            },
        },
        icon: { color: '#9bf7d3', fontSize: '5vh' },
        color: {
            background: 'linear-gradient(225deg, #009fff 0%, #29f39b 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
    })
);

export default Principal;
