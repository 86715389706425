import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';

import wallpaperMobile from '../assets/general/FONDO.svg';
import wallpaper from '../assets/general/wallpaper.svg';
import MyDrawer from './MyDrawer.jsx';
import MainView from './MainView.jsx';
import InstWin from './Install/DocInstWin.jsx';
import InstMac from './Install/DocInstMac';
import Requires from './Install/DocRequires';
import Empadronamiento from './Empadronamiento/Empadronamiento.jsx';
import Particle from './Particle';
import ProcAbiertos from './Permisos/ProcAbiertos';
import AntiVirus from './Permisos/AntiVirus';
import DesDescMac from './Permisos/DesDescMac';
import CamAndMicrophone from './Permisos/CamAndMicrophone';
import Exam from './Exam/Exam';
import Switcher from './Install/Switcher.jsx';
import PermSwitcher from './Permisos/PermSwitcher';
import AntivirusSwitcher from './Permisos/Antivirus/AntivirusSwitcher';
import Help from './Help';
import VirtualCams from './Permisos/VirtualCams';
import CamAndMicrophoneWin from './Permisos/CamAndMicrophoneWin';
import Pf from './Preguntas Frecuentes/PreguntasFrecuentes';



const App = () => {
    const classes = useStyles();
    const location = useLocation();

    const [path, setPath] = useState('');

    useEffect(() => {
        setPath(location.pathname.substring(0, 10));
    }, [location.pathname]);


    return (
        <Box
            sx={{
                display: { laptop: 'flex' },
                minHeight: '100vh',
                backgroundSize: { mobile: 'cover', laptop: 'fill' },
                backgroundRepeat: 'repeat',
                backgroundImage: { mobile: `url(${wallpaperMobile})`, laptop: `url(${wallpaper})` },
            }}
        >
            {/* <Particle /> */}
            <MyDrawer location={location.pathname} />
            <Box className={classes.info} sx={{ margin: { laptop: '30px 40px 0px 140px' } }}>
                <Routes>
                    <Route exact path="/" element={<MainView />} />

                    <Route path="/inst" element={<Switcher />} />

                    <Route path="/inst/win/1" element={<InstWin type={1} />} />
                    <Route path="/inst/win/2" element={<InstWin type={2} />} />

                    <Route path="/inst/mac/1" element={<InstMac type={1} />} />
                    <Route path="/inst/mac/2" element={<InstMac type={2} />} />

                    <Route path="/req" element={<Requires />} />

                    <Route path="/emp" element={<Empadronamiento />} />

                    <Route path="/exam" element={<Exam />} />

                    <Route path="/help" element={<Help />} />

                    <Route path="/perm" element={<PermSwitcher />} />

                    <Route path="/perm/antv" element={<AntivirusSwitcher />} />

                    {path === '/perm/antv' && (
                        <Route
                            path={'/perm/antv' + location.pathname.substring(10)}
                            element={<AntiVirus type={location.pathname.substring(11)} />}
                        />
                    )}
                    {location.pathname.includes('/perm/multi/m') && (
                        <Route
                            path={'/perm/mult' + location.pathname.substring(10)}
                            element={<CamAndMicrophone type={location.pathname.substring(12)} />}
                        />
                    )}
                    {location.pathname.includes('/perm/multi/w') && (
                        <Route
                            path={'/perm/mult' + location.pathname.substring(10)}
                            element={<CamAndMicrophoneWin type={location.pathname.substring(12)} />}
                        />
                    )}
                    <Route path="/perm/dev" element={<DesDescMac />} />

                    {path === '/perm/proc' && (
                        <Route
                            path={'/perm/proc' + location.pathname.substring(10)}
                            element={<ProcAbiertos type={location.pathname.substring(11)} />}
                        />
                    )}
                    {path === '/perm/vcam' && (
                        <Route
                            path={'/perm/vcam' + location.pathname.substring(10)}
                            element={<VirtualCams type={location.pathname.substring(11)} />}
                        />
                    )}

<Route path="/pf" element={<Pf />} />


                </Routes>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        info: {
            flexGrow: 1,
            width: { mobile: '100vw', laptop: `calc(100vw - 300px)` },
        },
    })
);
export default App;
