import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import windows from '../../assets/inst/windows.png';
import macos from '../../assets/inst/macos.png';

const Switcher = () => {
    const navigate = useNavigate();

    return (
        <Box
            flexDirection="column"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                sx={{
                    letterSpacing: '-2.4px',
                    fontFamily: 'DINPro-700',
                    fontSize: { mobile: '3.6rem', laptop: '40px' },
                    margin: { mobile: '60px auto', laptop: '30px auto' },
                }}
            >
                Selecciona tu sistema operativo
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { mobile: 'column', laptop: 'row' },
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    margin: { laptop: '50px auto' },
                    width: '65vw',
                    height: { mobile: '60vh', laptop: '' },
                }}
            >
                <Box
                    component="img"
                    src={windows}
                    sx={{
                        margin: '25px auto',
                        height: { mobile: '400px', laptop: '270px' },
                        transition: 'all 0.35s ease-in-out',
                        filter: { mobile: 'grayscale(0)', laptop: 'grayscale(.5)' },
                        '&:hover': {
                            transform: 'scale(1.15)',
                            cursor: 'pointer',
                            filter: 'grayscale(0)',
                        },
                    }}
                    onClick={() => navigate('/inst/win/1')}
                />

                <Box
                    component="img"
                    src={macos}
                    sx={{
                        margin: '25px auto',
                        height: { mobile: '400px', laptop: '270px' },
                        transition: 'all 0.35s ease-in-out',
                        filter: { mobile: 'grayscale(0)', laptop: 'grayscale(.5)' },
                        '&:hover': {
                            transform: 'scale(1.15)',
                            cursor: 'pointer',
                            filter: 'grayscale(0)',
                        },
                    }}
                    onClick={() => navigate('/inst/mac/1')}
                />
            </Box>
        </Box>
    );
};

export default Switcher;
