
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from "@mui/material/OutlinedInput";
// import AccountCircle from '@mui/icons-material/AccountCircle';
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import DocSys from "./DocSys";
import DocLms from "./DocLms";
import Doc from "../Install/DocInstMac";
import { BsNutFill } from "react-icons/bs";
import { Tab, Tabs } from "@mui/material";
import DocDesktop from "./DocDesktop";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

function DocEmpadronamientoo() {
  const [u, setU] = useState("");
  const [pos, setPos] = useState(true);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setU(event.target.value);
    setPos(false);
  };

  // const unis = ['UES21', 'TECLAB', 'IPP', 'CANVAS (UDEC, OTROS)', 'MOODLE', 'OTROS'];
  const unis = [
    {label: "UES21"},
    {label: "TECLAB"},
    {label: "IPP"},
    {label: "Universidad de la Mariana Mercante"},
    {label: "Universidad PUCP"},
    {label: "Universidad ONMEX"},
    {label: "LONDRES"},
    {label: "OTROS"},
    {label: "Teclab Perú"},
    {label: "Universidad de Barceló"},
    {label: "Universidad Santo Tomas"},
  ];


  return (
    <>
      <Box
        sx={{
          alignItems: "inherit",
          margin: "0px auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                
                Registro
            </Typography>
            {/* <Divider /> */}
        {/* {pos ? <div style={{ height: "30vh" }}></div> : null} */}

        {/* <div
          style={{
            alignItems: "inherit",
            margin: "0px auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
             <div
          style={{
            alignItems: "inherit",
            margin: "0px auto",
            display: "flex",
            flexDirection: "column",
          }}
        > */}
          {/* <Typography
            variant="body1"
            sx={{
              fontSize: { mobile: "2.5rem", laptop: "1rem" },
              marginBottom: { mobile: "50px", laptop: "0px" },
              textAlign: "center",
              padding: "20px",
              fontWeight: "bold",
              fontFamily: "DINPro",
            }}
          >
            Busca tu institución educativa
          </Typography> */}

          {/* <Autocomplete
        //   handleChange={(x)=>{
        //     console.log(x)

        //   }}
            onChange={(x,y)=>{
                console.log(y)
                setU((y && y.label) ? y.label : "")
            }}
            disablePortal
            id="combo-box-demo"
            options={unis}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Universidad" />}
          /> */}
          <DocLms uni={u} tabN={0} type="moodle" />
          {/* </div> */}

          {/* <FormControl
            fullWidth
            sx={{
              maxWidth: { mobile: "30vw", laptop: "20vw" },
              margin: "0 auto",
              backgroundColor: "#fff",
              transform: { mobile: "scale(2)", laptop: "scale(1)" },
            }}
          >
            <InputLabel id="demo-simple-select-label">Universidad</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={u}
              label="Universidad"
              onChange={(e) => {
                handleChange(e);
              }}
              input={
                <OutlinedInput
                  label="Universidad"
                  sx={{
                    textAlign: "center",
                    width: { mobile: "30vw", laptop: "20vw" },
                  }}
                />
              }
              sx={{ width: { mobile: "30vw", laptop: "20vw" } }}
              variant="outlined"
            >
              {unis.map((universidad) => (
                <MenuItem
                  value={universidad}
                  align="center"
                  sx={{
                    alignContent: "center",
                    justifyContent: "center",
                    letterSpacing: 1.5,
                    fontSize: { mobile: "2rem", laptop: "1rem" },
                    padding: "20px",
                    margin: { mobile: "60px auto", laptop: "auto" },
                    width: "100%",
                    borderBottom: ".5px solid gray",
                  }}
                >
                  {universidad}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {/* {!pos && (u === 'MOODLE' || u === 'OTROS' ||  u === 'CANVAS (UDEC, OTROS)' ||u === 'UGM' ) ?<div style={{paddingLeft:'20px',paddingRight:'20px'}}> <Box sx={{ paddingTop:'20px',width: '100%', bgcolor: 'background.paper',borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChangeTab} centered>
                            <Tab label="Desktop" value={0} />
                            <Tab label="Mobile" value={1} />
                        </Tabs>
                    </Box> </div>:
                        null
                    } */}

          {/* <Box>
                        {u === 'OTROS' ? (
                            <DocLms uni={u} tabN={0} type="moodle" />
                        ) : u === 'MOODLE' ? (
                            <DocLms uni={u} tabN={0} type="moodle" />
                        ) : u === 'CANVAS (UDEC, OTROS)' ? (
                            <DocLms uni={u} tabN={0} type="canvas" />
                        ) : u === 'UGM' ? (
                            <DocLms uni={u} tabN={0} type="moodle" />
                        ) : u === 'UES21' ? (
                            <DocSys uni={u} tabN={1} />
                        ) : u === 'TECLAB' ? (
                            <DocSys uni={u} tabN={0} />
                        ) : u === 'IPP' ? (
                            <DocSys uni={u} tabN={0} />
                        ) : (
                            null
                        )}
                    </Box> */}
          {/* <Box>
            {u === "OTROS" ? (
              <DocLms uni={u} tabN={0} type="moodle" />
            ) : u === "MOODLE" ? (
              <DocLms uni={u} tabN={0} type="moodle" />
            ) : u === "CANVAS" ? (
              <DocLms uni={u} tabN={0} type="canvas" />
            ) : u === "UGM" ? (
              <DocLms uni={u} tabN={0} type="moodle" />
            ) : u === "UES21" ? (
              <DocSys uni={u} tabN={1} />
            ) : u === "TECLAB" ? (
              <DocDesktop uni={u} tabN={0} />
            ) : u === "IPP" ? (
              <DocDesktop uni={u} tabN={0} />
            ) : u.length > 0 ? (
              <DocDesktop uni={u} tabN={0} />
            ) : null}
          </Box> */}
        {/* </div> */}
      </Box>
    </>
  );
}
export default DocEmpadronamientoo;
