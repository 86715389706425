import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SvgIcon } from '@material-ui/core';
import Picture1 from '..//assets/emp/desktop/Picture1.png';
import Picture2 from '../assets/emp/desktop/Picture2.png';

import Picture3 from '../assets/emp/desktop/Picture3.png';
import Picture4 from '../assets/emp/desktop/Picture4.png';
import Picture5 from '../assets/emp/desktop/Picture5.png';
import Picture6 from '../assets/emp/desktop/Picture6.png';
import Picture7 from '../assets/emp/desktop/Picture7.png';

import Picture8 from '../assets/emp/desktop/Picture8.png';
import Picture9 from '../assets/emp/desktop/Picture9.png';
import reg7 from '../assets/reg7.png';




const EmpDesktop = ({ uni, type }) => {

    const classes = useStyles();

    return (
        <div>
            {/* PRIMERA SECCION DE IMAGENES */}

            <Box className={classes.boxesContainer} sx={{ flexDirection: { mobile: 'column', laptop: 'column' } }}>
                <Box
                    className={classes.boxes}
                    // sx={{
                    //     padding: '40px',
                    //     '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    // }}
                >
                    <Box
                        component="img"
                        src={Picture1}
                        className={classes.imagen}

                    />
                    {/* <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        Seleccionar <i>"Ingresar Ahora"</i>
                    </Typography> */}
                </Box>

                <Typography
                    variant="h6"
                    sx={{
                        fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        margin: { mobile: '5rem auto', laptop: '20px auto' },
                        color: '#667',
                        fontFamily: 'DINPro'
                    }}
                >
                    6.	Toma una foto de tu rostro sobre un fondo liso e iluminado. Sigue las instrucciones que aparecen a continuación.
                </Typography>
                <Box
                    className={classes.boxes}
                    // sx={{
                    //     padding: '40px',
                    //     // height: { mobile: '72vh', laptop: '73vh' },
                    //     '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    // }}
                >
                    <Box
                        component="img"
                        src={Picture2}
                        // sx={{ width: { mobile: '65vw', laptop: '55vw' },
                        // //  height: { mobile: '65vh', laptop: '66vh' }
                        //  }}
                        className={classes.imagen}
                    />

                </Box>

                <Box
                    className={classes.boxes}
                    // sx={{
                    //     '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    //     padding: '40px'
                    //     // width: { mobile: '65vw', laptop: '55vw' },
                    // }}
                >
                    <Box
                        component="img"
                        // sx={{ width: { mobile: '65vw', laptop: '45vw' },  }}
                        src={Picture3}
                        className={classes.imagen}
                    />

                </Box>
            </Box>



            <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#667',
                    fontFamily: 'DINPro'
                }}
            >
                7.	Captura una foto clara de tu identificación personal. Recuerda ubicar la misma dentro del recuadro que aparece en pantalla.
            </Typography>
            {/* SEGUNDA SECCION DE IMAGENES */}
            <Box className={classes.boxesContainer} sx={{ flexDirection: 'column' }}>
                <Box
                    className={classes.boxes}
                    // sx={{
                    //     padding: '40px',
                    //     '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    // }}
                >
                    <Box
                        component="img"
                        src={Picture4}
                        className={classes.imagen}
                    />

                </Box>

                <Box
                    className={classes.boxes}
                    // sx={{
                    //     padding: '40px',
                    //     '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    // }}
                >
                    <Box
                        component="img"
                        src={Picture5}
                        className={classes.imagen}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold',color:"rgb(237, 108, 2)", maxWidth: 'fit-content', fontSize: { mobile: '2.5rem', laptop: '1rem' }, fontFamily: 'DINPro' }}
                    >
                        IMPORTANTE: Esta imagen es ilustrativa, lo fundamental es que la identificación quede en el rectángulo, independientemente del lado en que se encuentren los datos.
                    </Typography>
                    <Typography
                    variant="h6"
                    sx={{
                        fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        margin: { mobile: '5rem auto', laptop: '20px auto' },
                        color: '#667',
                        fontFamily: 'DINPro'
                    }}
                >
                    En caso de no poder tomar correctamente la foto, deberás acceder a “¿Problemas para empadronarte?” ubicado en la parte inferior de la ventana. Completa los datos solicitados, para que un auditor los valide manualmente.
                </Typography>
                </Box>
                {/* <Typography
                    variant="h6"
                    sx={{
                        fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        margin: { mobile: '5rem auto', laptop: '20px auto' },
                        color: '#667',
                    }}
                >
                    5.	Completar el dato de ID con el número presente en la identificación y crear un usuario de acceso a la app mobile
                </Typography> */}
                {/* <Box
                    className={classes.boxes}
                    sx={{
                        padding: '40px',
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    }}
                >
                    <Box
                        component="img"
                        src={Picture6}
                        className={classes.imagen}
                    />

                </Box> */}
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        margin: { mobile: '5rem auto', laptop: '20px auto' },
                        color: '#667',
                        fontFamily: 'DINPro'
                    }}
                >
                    8.	Para finalizar, presiona el botón GUARDAR. Inmediatamente el estado incompleto del registro cambiará a registro completo, acompañado de una tilde verde.
                </Typography>
               
            
                <Box
                    className={classes.boxes}
                    // sx={{
                    //     padding: '40px',
                    //     '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    // }}
                >
                    {/* <Box
                        component="img"
                        src={Picture8}
                        className={classes.imagen}
                    /> */}
                    <Box
                        component="img"
                        src={reg7}
                        className={classes.imagen}
                        sx={{ width: { laptop: '80vw' }, height: { laptop: '50vw'} }}
                    />

                </Box>
                
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        margin: { mobile: '5rem auto', laptop: '20px auto' },
                        color: '#667',
                        fontFamily: 'DINPro'
                    }}
                >
                    9. Presiona CONFIRMAR para finalizar este paso que realizará por única vez.
                </Typography>
                {/* <Typography
                    variant="h6"
                    sx={{
                        fontSize: { mobile: '2.3rem', laptop: '1.1rem' },
                        fontFamily: 'Bai Jamjuree',
                        textAlign: 'center',
                        margin: { mobile: '5rem auto', laptop: '20px auto' },
                        color: '#14b0ee',
                    }}
                >
                     En caso que por algún motivo el estudiante no pudiera completar el registro, deberá acceder a ¿Problemas para registrarse?. Un auditor realizará la validación manualmente.
                </Typography>
               
                <Box
                    className={classes.boxes}
                    sx={{
                        padding: '40px',
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    }}
                >
                    <Box
                        component="img"
                        src={Picture9}
                        className={classes.imagen}
                    />

                </Box> */}
            </Box>
            {/* FIN SEGUNDA SECCION */}
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesContainer: {
            maxWidth: '80vw',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            margin: '10px auto',
        },
        boxes: {
            display: 'flex',
            flexDirection: 'column',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            transition: '.3s all ease',
            backgroundColor: '#fff',
        },
        imagen: {
            margin: '0 auto',
            borderRadius: '10px',
            objectFit: 'none',
        },
        icon: { fontSize: '70px', color: '#9bf7d3', margin: '50px 10px' },
    })
);

export default EmpDesktop;
