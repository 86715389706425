import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import av24 from '../../../assets/perm/antiVirus/av24.png';

const Kaspersky = () => {
    const classes = useStyles();
    const kaspersky = [
        'Abrír la ventana de configuración de la aplicación.',
        'En la parte izquierda de la ventana, en la sección Configuración general, seleccionar Exclusiones. La configuración de las exclusiones se mostrará en la parte derecha de la ventana.',
        'En la sección Exclusiones de análisis y aplicaciones de confianza, hacer click en el botón Configuración. Se abrirá la ventana Zona de confianza en la pestaña Exclusiones del análisis.',
        `En la sección Exclusiones de análisis y aplicaciones de confianza, hacer click en el botón Configuración. Se abrirá la ventana Zona de confianza en la pestaña Exclusiones del análisis.`,
        {
            menu: 'Para excluir un archivo o una carpeta del análisis:',
            cat: [
                `En la sección Propiedades, seleccionar la casilla de verificación Archivo o carpeta. `,
                `Hacer click en el enlace Seleccionar archivo o carpeta de la sección Descripción de la exclusión del análisis para abrir la ventana Nombre de archivo o carpeta.`,
                `Introducir el archivo o el nombre de la carpeta, o bien la máscara del archivo o del nombre de carpeta. También se puede seleccionar el archivo o carpeta en el árbol de carpetas haciendo click en Examinar.`,
            ],
        },
        {
            menu: 'Especificar los componentes de Kaspersky Endpoint Security que van a emplearse en la exclusión del análisis:',
            cat: [
                'Hacer click en el enlace Todos de la sección Descripción de la exclusión del análisis para abrir el enlace Seleccionar componentes.',
                'Hacer click en el enlace Seleccionar componentes para abrir la ventana Componentes de protección.',
                'Seleccionar las casillas de verificación situadas junto a los componentes a los cuales se debe aplicar la exclusión del análisis.',
                'En la ventana Componentes de protección, hacer click en Aceptar.',
            ],
        },
        {
            menu: '* Si se especifican los componentes en la configuración de la exclusión del análisis, dicha exclusión se aplica solo durante el análisis realizado por estos componentes de Kaspersky Endpoint Security.',
            cat: [
                'En la ventana Exclusión del análisis, hacer click en Aceptar',
                'La exclusión del análisis agregado aparecerá en la tabla en la pestaña Exclusiones del análisis de la ventana Zona de confianza. Los parámetros configurados de esta exclusión del análisis aparecen en la sección Descripción de la exclusión del análisis.',
                'En la ventana Zona de confianza, hacer click en Aceptar.',
                'Para guardar los cambios realizados, hacer click en el botón Guardar.',
            ],
            warn: true,
        },
    ];
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    width: { mobile: '90vw', laptop: '40vw' },
                    height: { mobile: '20vh', laptop: '15.3rem' },
                    borderRadius: '10px',
                    margin: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                    WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                }}
                component="img"
                src={av24}
            />
            <Box className={classes.boxes}>
                <ol>
                    {kaspersky.map((e) => {
                        return (
                            <Box>
                                {e.menu ? (
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'DINPro',
                                                fontSize: { mobile: '2rem', laptop: '1rem' },
                                                margin: { mobile: ' 2rem auto 0px 0px', laptop: '15px' },
                                            }}
                                        >
                                            <li> {e.menu} </li>
                                            <ul>
                                                {e.cat.map((submenu) => (
                                                    <li className={classes.subMenu}>{submenu}</li>
                                                ))}
                                            </ul>
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography
                                        sx={{
                                            fontFamily: 'DINPro',
                                            fontSize: { mobile: '2rem', laptop: '1rem' },
                                            margin: { mobile: ' 2rem auto 0px 0px', laptop: '15px' },
                                        }}
                                    >
                                        <li>{e}</li>
                                    </Typography>
                                )}
                            </Box>
                        );
                    })}
                </ol>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgTitle: { borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { maxWidth: '80vw', flex: 1, padding: '20px', textAlign: 'center' },
        subMenu: { padding: '10px' },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default Kaspersky;
