import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { BsApple } from 'react-icons/bs';
import { DiAndroid } from 'react-icons/di';

import mac00 from '../../assets/inst/mac/mac00.png';
import mac1 from '../../assets/inst/mac/mac1.png';
import mac2 from '../../assets/inst/mac/mac2.png';
import mac3 from '../../assets/inst/mac/mac3.png';
import mac4 from '../../assets/inst/mac/mac4.png';
import mac5 from '../../assets/inst/mac/mac5.png';
import mac6 from '../../assets/inst/mac/mac6.png';
import mac7 from '../../assets/inst/mac/mac7.png';
import mac8 from '../../assets/inst/mac/mac8.png';
import dev_desc from '../../assets/inst/mac/dev_desc.png';
import tipomac from '../../assets/inst/mac/tipomac.png';
import tipomac1 from '../../assets/inst/mac/tipomac1.png';
import tipomac2 from '../../assets/inst/mac/tipomac2.png';
import tipomac3 from '../../assets/inst/mac/tipomac3.png';


import firefoxopera from '../../assets/inst/mac/firefoxopera.png';
import navegador from '../../assets/inst/mac/navegador.png';
import descarga1 from '../../assets/inst/mac/descarga1.png';
import descarga2 from '../../assets/inst/mac/descarga2.png';
import descarga3 from '../../assets/inst/mac/descarga3.png';
import descarga4 from '../../assets/inst/mac/descarga4.png';
import descarga5 from '../../assets/inst/mac/descarga5.png';
import descarga6 from '../../assets/inst/mac/descarga6.png';
import descarga7 from '../../assets/inst/mac/descarga7.png';
import descarga8 from '../../assets/inst/mac/descarga8.png';
import descarga9 from '../../assets/inst/mac/descarga9.png';
import linkprovisto from '../../assets/inst/mac/linkprovisto.png';
import eligeubic from '../../assets/inst/mac/eligeubic.png';
import descargamac from '../../assets/inst/mac/descargamac.png';
import chromemac from '../../assets/inst/mac/chromemac.png';
import opera from '../../assets/inst/mac/opera.png';
import firefoxmac from '../../assets/inst/mac/firefoxmac.png';
import safari from '../../assets/inst/mac/safari.png';
import dwlchrome from '../../assets/inst/mac/dwlchrome.png';
import dwlchrome1 from '../../assets/inst/mac/dwlchrome1.png';







import explorer from '../../assets/pdf/browser_MAC.pdf';

const DocInstMac = (props) => {
    const { type } = props;
    const classes = useStyles();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />

            <div>
            <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'Bai Jamjuree',
                        fontWeight: 'bold',
                        fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                        marginLeft: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: { mobile: 'center', laptop: 'left' },
                    }}
                >
                    ¿Cómo saber qué tipo de MAC tengo?
                    </Typography>
                <Divider />
                <Box
                
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >


                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                    Abre “Configuración del sistema” > “General “ > “Información”
                    </Typography>
                    <Box>
                    <Box component="img" src={tipomac} className={classes.imagen} sx={{ width: { laptop: '22vw' }, padding: 1 }} />
                    <Box component="img" src={tipomac1} className={classes.imagen} sx={{ width: { laptop: '22vw' }, padding: 1 }} />
                    </Box>
                    <Box>
                    <Box component="img" src={tipomac2} className={classes.imagen} sx={{ width: { laptop: '22vw' }, padding: 1 }} />
                    <Box component="img" src={tipomac3} className={classes.imagen} sx={{ width: { laptop: '22vw' }, padding: 1 }} />
                    </Box>
                    


                </Box>

                {/* <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'Bai Jamjuree',
                        fontWeight: 'bold',
                        fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                        marginLeft: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: { mobile: 'center', laptop: 'left' },
                    }}
                >
                    Descargar Klarway */}
                    
                    {/* <Box sx={{ display: { mobile: 'none', laptop: 'initial' } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography
                                className={classes.text}
                                sx={{
                                    maxWidth: { laptop: '90vw' },
                                    fontSize: { mobile: '2.2rem', laptop: '1.1rem' },
                                    fontWeight: 'bold',
                                }}
                            >
                                Si usas Firefox, Opera o Zafari
                            </Typography>
                            <Button
                                href={explorer}
                                target={'_blank'}
                                rel="noreferrer"
                                variant="contained"
                                sx={{
                                    textDecoration: 'none',
                                    textDecorationLine: 'none',
                                    fontSize: { mobile: '1.7rem', laptop: '1.1rem' },
                                    margin: { mobile: '40px auto', laptop: '0px' },
                                }}
                            >
                                hacé click acá
                            </Button>{' '}
                        </Box>
                    </Box> */}
                {/* </Typography> */}
                {/* <Divider />
                <Box sx={{ display: { mobile: 'initial', laptop: 'none' } }}>
                    <Box
                        className={classes.boxes}
                        sx={{
                            margin: { mobile: '70px auto', laptop: '30px auto' },
                            flexDirection: { mobile: 'row', laptop: 'row' },
                            textAlign: 'center',
                            borderRadius: '12px',

                            width: { mobile: '80%', laptop: '80%' },
                        }}
                    >
                        <Typography
                            className={classes.text}
                            sx={{
                                maxWidth: { laptop: '90vw' },
                                fontSize: { mobile: '2rem', laptop: '1.1rem' },
                                fontWeight: 'bold',
                            }}
                        >
                            Si usas Firefox, Opera o Zafari
                        </Typography>
                        <Button
                            href={explorer}
                            target={'_blank'}
                            rel="noreferrer"
                            variant="contained"
                            sx={{
                                textDecoration: 'none',
                                textDecorationLine: 'none',
                                fontSize: { mobile: '1.7rem', laptop: '1.1rem' },
                                margin: { mobile: '10px auto', laptop: '0px' },
                            }}
                        >
                            hacé click acá
                        </Button>{' '}
                    </Box>
                </Box>

                
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, textAlign: 'center', fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Elige nombre y ubicación del archivo
                    </Typography>
                    <Box
                        component="img"
                        src={eligeubic}
                        className={classes.imagen}
                        sx={{ width: { mobile: '100%', laptop: '22vw' } }}
                    />
                </Box> */}
                {/* <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        paddingTop: '20px',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Por último, esperar a que complete la descarga para proceder con la{' '}
                        <Link to="/inst/mac/2" className={classes.noLink} onClick={() => window.scrollTo(0, 0)}>
                            <Button variant="text">instalación</Button>
                        </Link>
                        .
                    </Typography>

                    <Box
                        component="img"
                        src={mac3}
                        className={classes.imagen}
                        sx={{ width: { mobile: '100%', laptop: '50vw' } }}
                    />
                </Box> */}

                {/* <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                    Una vez completada la descarga, abre el programa. Podrás encontrar el archivo de
acuerdo a tu navegador.
                    </Typography>
                    <Box component="img" src={navegador} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>
            
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                    Chrome
                    </Typography>
                    <Box component="img" src={chrome} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                    Safari
                    </Typography>
                    <Box component="img" src={safari} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                    Firefox y Opera
                    </Typography>
                    <Box component="img" src={firefoxopera} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box> */}

                <Typography
                    variant="h4"
                    sx={{
                        fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                        textAlign: { mobile: 'center', laptop: 'left' },
                        fontFamily: 'Bai Jamjuree',
                        fontWeight: 'bold',
                        marginLeft: '20px',
                        marginTop: '120px',
                    }}
                >
                    Descarga e instala Klarway
                </Typography>

                <Divider />

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >

                <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}>
                    Antes de comenzar recomendamos corroborar los{' '}
                    <Link to="/req" className={classes.link}>
                        requisitos
                    </Link>{' '}
                    para el correcto funcionamiento de Klarway. En caso de no alcanzar los recursos necesarios Klarway
                    forzará el inicio de la aplicación, sin embargo, la misma podría sufrir{' '}
                    <b>tiempos de espera más largos</b> y estar abierto a la <b>posibilidad de fallos</b>.
                </Typography>

               
                <Box component="img" src={descargamac} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />

                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}>
                        Haz click en el link provisto por la institución para descargar Klarway. La descarga comenzará
                        en forma automática.
                    </Typography>
                    <Box component="img" src={linkprovisto} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es chrome lo encontrará aquí.
                    </Typography>
                    <Box component="img" src={dwlchrome1} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es firefox lo encontrará aquí.
                    </Typography>
                    <Box component="img" src={firefoxmac} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es safari lo encontrará aquí.
                    </Typography>
                    <Box component="img" src={chromemac} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Si su navegador es opera lo encontrará aquí.
                    </Typography>
                    <Box component="img" src={opera} className={classes.imagen} sx={{ width: { laptop: '22vw' } }} />
                </Box>


                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    
                    <Typography
                        className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        {' '}
                        Localiza Klarway en la carpeta de descargas y abre el instalador.
                    </Typography>

                    <Box
                        component="img"
                        src={descarga1}
                        className={classes.imagen} sx={{ width: { laptop: '22vw' } }} 
                    />
                </Box>


                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                <Typography
                    className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                >
                    {' '}
                    Podrás visualizar una nueva ventana con las instrucciones que deberás seguir.
                </Typography>
                    <Box
                        component="img"
                        src={descarga2}
                        className={classes.imgBig}
                        sx={{ width: { mobile: '30%', laptop: '20vw' } }}
                    />
                    <Box
                        component="img"
                        src={descarga3}
                        className={classes.imagen} sx={{ width: { laptop: '20vw' } }}
                    />
                    <Box
                        component="img"
                        src={descarga4}
                        className={classes.imagen} sx={{ width: { laptop: '20vw' } }}
                    />

                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.center} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}
                    >
                        Se te solicitará que coloques tu contraseña para poder continuar con la
instalación.
                    </Typography>
                    <Box
                        component="img"
                        src={descarga5}
                        className={classes.imagen} sx={{ width: { laptop: '22vw' } }} 
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.center} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}
                    >
                        Si todos los pasos fueron realizados correctamente, aparecerá el siguiente
mensaje.
                    </Typography>
                    <Box
                        component="img"
                        src={descarga6}
                        className={classes.imagen} sx={{ width: { laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.center} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}
                    >
                        Al presionar cerrar, aparecerá el siguiente cartel donde podrás optar por
transferir el instalador al basurero, o bien conservarlo.
                    </Typography>
                    <Box
                        component="img"
                        src={descarga7}
                        className={classes.imagen} sx={{ width: { laptop: '22vw' } }}
                    />
                </Box>
                <Box
                   className={classes.boxes}
                   sx={{
                       margin: { mobile: '70px 30px', laptop: '30px' },
                       flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                   }}
                >
                    <Typography
                        className={classes.center} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}
                    >
                        Podrás visualizar el icono de Klarway en el Launchpad.
                    </Typography>
                    <Box
                        component="img"
                        src={descarga8}
                        className={classes.imagen} sx={{ width: { laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography
                        className={classes.center} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' }, width: { laptop: '22vw' } }}
                    >
                        Una vez iniciado Klarway podrás buscar tu institución educativa, como lo indica
la siguiente imagen.
                    </Typography>
                    <Box
                        component="img"
                        src={descarga9}
                        className={classes.imagen} sx={{ width: { laptop: '22vw' } }}
                    />
                </Box>

                {/* <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Si el sistema operativo permite la instalación, esta misma comenzará instantaneamente.
                    </Typography>
                    <Box
                        component="img"
                        src={mac5}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        justifyContent: 'space-between',
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Mover la app de Klarway a la carpeta de Apps.
                    </Typography>
                    <Box
                        component="img"
                        src={mac00}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                            Si la instalación <b>no</b> comienza y aparece el siguiente cartel, seleccionar{' '}
                            <b>cancelar</b> y otorgar los permisos correspondientes.{' '}
                        </Typography>

                        <Link to="/perm/dev" className={classes.noLink}>
                            <Button
                                variant="contained"
                                sx={{
                                    textDecoration: 'none',
                                    textDecorationLine: 'none',
                                    fontSize: { mobile: '1.7rem', laptop: '1rem' },
                                    margin: { mobile: '40px auto', laptop: '20px auto' },
                                }}
                            >
                                Permisos de instalación{' '}
                            </Button>
                        </Link>
                    </Box>
                    <Box
                        component="img"
                        src={dev_desc}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
                    <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        Al finalizar la instalación, Klarway se mostrará en el cajon de aplicaciones, hacer click para
                        abrir.
                    </Typography>
                    <Box
                        component="img"
                        src={mac6}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: 'column',
                        padding: '20px',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography className={classes.text} sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                            Al iniciar la aplicación conceder los permisos a Klarway para acceder a tu cámara y
                            micrófono.
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        src={mac8}
                        className={classes.imgBig}
                        sx={{ width: { mobile: '100%', laptop: '60vw' } }}
                    />
                    <Typography sx={{ fontSize: { mobile: '1.7rem', laptop: '1rem' } }}>
                        En caso de no estar registrado para poder rendir, hacer click en el siguiente boton.
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                margin: '20px auto',
                                marginRight: '10px',
                            }}
                        >
                            <Link to="/emp" className={classes.noLink}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textDecoration: 'none',
                                        textDecorationLine: 'none',
                                        fontSize: { mobile: '1.7rem', laptop: '1rem' },
                                        margin: { mobile: '40px auto', laptop: '0px' },
                                    }}
                                >
                                    Registro
                                </Button>
                            </Link>
                        </Box>
                    </Typography>
                </Box> */}
            </div>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxes: {
            display: 'flex',
            padding: '20px 40px',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            backgroundColor: '#fff',
        },
        imagen: {
            // width: '22vw',
            margin: '20px auto',
            borderRadius: '10px',
        },
        imgBig: { margin: '20px auto', borderRadius: '10px' },
        link: { color: '#0cbcd8', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer',fontFamily: 'DINPro' },
        text: { margin: 'auto 20px', fontFamily: 'DINPro' },
        center: { margin: '50px', textAlign: 'center', backgroundColor: '#fff' },
        noLink: { textDecoration: 'none' },
    })
);

export default DocInstMac;
