import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

const Card = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <Box
            onClick={() => navigate(props.link)}
            sx={{
                width: { mobile: '80%', laptop: '45%' },
                height: { mobile: '180px', laptop: '150px' },
                borderRadius: '30px',
                boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                margin: '15px',
                transition: '0.15s transform ease-in-out',
                '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.05)' }, cursor: 'pointer' },
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 30px',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        maxWidth: '65%',
                        margin: 'auto 0px',
                        alignItems: 'center',
                    }}
                >
                    {props.reactIcon ? (
                        <Icon children={props.icon} classes={{ root: classes.iconRoot }}></Icon>
                    ) : (
                        <Icon classes={{ root: classes.iconRoot }} sx={{ mr: { mobile: '20px', laptop: '10px' } }}>
                            <Box
                                component="img"
                                className={classes.imageIcon}
                                // sx={{ height: { mobile: '100%', laptop: '50px' } }}
                                src={props.icon}
                            />
                        </Icon>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            sx={{
                                letterSpacing: '-1.35px',
                                fontFamily: 'DINPro-900',
                                fontSize: { mobile: '2.4rem', laptop: '25px' },
                                lineHeight: '1',
                            }}
                        >
                            {props.title}
                        </Typography>
                        <Typography
                            sx={{
                                letterSpacing: '-0.2px',
                                fontFamily: 'DINPro-500',
                                fontSize: { mobile: '1.4rem', laptop: '15px' },
                                color: '#828282',
                            }}
                        >
                            {props.subtitle}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ minWidth: '80px' }}>
                    <IconButton
                        sx={{
                            borderRadius: '13px',
                            height: { mobile: '70px', laptop: '50px' },
                            width: { mobile: '75px', laptop: '55px' },
                            boxShadow: '0 5px 40px 0 rgba(145, 151, 179, 0.4)',
                            fontSize: { mobile: '3.3rem', laptop: '2.2rem' },
                            '&:hover': { backgroundColor: '#fff' },
                            // marginBottom: '15px',
                        }}
                        onClick={() => navigate(props.link)}
                    >
                        <MdOutlineKeyboardArrowRight />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        iconRoot: {
            height: '100%',
            width: '70px',
            textAlign: 'center',
        },
    })
);

export default Card;
