import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@material-ui/core/Divider";
import { ExpandLess } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const PreguntaFrecuente = () => {
  // var showAsk = Boolean(false)
  const classes = useStyles();
  const [showAsk, setShow] = React.useState(false);
  return (
    <Box>
      {/* <Box
        className={classes.boxes}
        sx={{
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
        style={{ alignItems: "baseline", flexDirection:"column", display: "block" }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Bai Jamjuree",
            //   fontWeight: "bold",
            margin: 1,
            fontSize: { mobile: "3.3rem", laptop: "2.1rem" },
            textAlign: { mobile: "center", laptop: "left" },
          }}
        >
          {"Preguntas Frecuentes (Registro Actual)"}
        </Typography>
        <Divider/>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Qué ocurre si no se puede tomar alguna de las fotografías?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Deberás hacer clic en el botón “Problemas para empadronarme” y
              completar el registro manual, para que luego la institución valide
              tus datos. <br /> Si quieres tomar alguna de las imágenes
              nuevamente, ten en cuenta los siguientes consejos:
              <ul>
                <li>
                  Tu cara debe verse completa. Evita accesorios como, por
                  ejemplo, gafas, gorros, auriculares, pañuelos, bufandas, etc.
                </li>
                <li>Ubícate en un lugar iluminado y con fondo liso.</li>
                <li>Asegúrate de que la imagen se vea nítida.</li>
                <li>Limpia la cámara de tu dispositivo.</li>
                <li>Intenta en otro lugar o con otra computadora.</li>
                <li>
                  Prueba con tu pasaporte o licencia de conducir, en caso de no
                  poder tomar la fotografía de tu identificación.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Tengo que registrarme cada vez que realizo el examen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No, el registro se realiza solo una vez. Luego podrás realizar tus
              exámenes.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Qué debo hacer si deseo cambiar mi registro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Deberás volver a la sección “Registro Klarway” y, desde allí,
              modificar lo que desees. En caso de experimentar inconvenientes,
              es necesario que te comuniques con tu institución.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Dónde encuentro el estado de mi registro manual?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Deberás ingresar a la sección “Registro Klarway”, donde podrás
              encontrar tres resultados posibles:
              <ul>
                <li>
                  Aprobado: tu registro está completo y puedes realizar
                  exámenes.
                </li>
                <li>
                  En revisión: tu registro continúa en verificación y aún no
                  puedes realizar exámenes.
                </li>
                <li>
                  Desaprobado: tendrás que registrarte nuevamente y no puedes
                  realizar exámenes hasta que quede aprobado.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box> */}

      <Box
        className={classes.boxes}
        sx={{
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
        style={{ alignItems: "baseline", flexDirection:"column", display: "block" }}
      >
        {/* <Typography
          variant="h2"
          sx={{
            fontFamily: "Bai Jamjuree",
            //   fontWeight: "bold",
            margin: 1,
            fontSize: { mobile: "3.3rem", laptop: "2.1rem" },
            textAlign: { mobile: "center", laptop: "left" },
          }}
        >
          {"Preguntas frecuentes"}
        </Typography> */}
        <Divider />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Qué ocurre si no se puede tomar la fotografía de mi rostro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{fontWeight:'bold'}}>
              Deberás contactarte con la mesa de ayuda de tu institución para
              recibir asesoramiento. El registro podrá completarse cuando la
              imagen haya sido correctamente tomada. <br /> Ten en cuenta los
              siguientes consejos:
              <ul>
                <li>
                  Tu cara debe verse completa. Evita usar accesorios como, por
                  ejemplo, gafas, gorros, auriculares, pañuelos, bufandas, etc.
                </li>
                <li>Ubícate en un lugar iluminado y con fondo liso.</li>
                <li>Limpia la cámara de tu dispositivo.</li>
                <li>Intenta en otro lugar o con otra computadora.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Qué ocurre si no se puede tomar la fotografía de mi
              identificación?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{fontWeight:'bold'}}>
              Deberás aguardar a que la institución valide tus datos de forma
              manual. Recuerda que solo podrás realizar exámenes una vez que el
              registro haya sido aprobado. <br /> En ocasiones, la fotografía no
              puede ser tomada porque la imagen del rostro está oscura o tiene
              marcas que impiden su correcta captura. Te sugerimos estos tips:
              <ul>
                <li>Prueba con tu pasaporte o licencia de conducir.</li>
                <li>Asegúrate de que la imagen se vea nítida.</li>
                <li>Intenta en otro lugar o con otra computadora.</li>
                <li>Limpia la cámara de tu dispositivo.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Tengo que registrarme cada vez que realizo el examen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{fontWeight:'bold'}}>
              No, el registro se realiza solo una vez. Luego podrás realizar tus
              exámenes.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Qué debo hacer si deseo cambiar mi registro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{fontWeight:'bold'}}>
              Deberás comunicarte con tu institución y solicitar el pedido.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.text}
              sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
            >
              ¿Dónde encuentro el estado de mi registro manual?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{fontWeight:'bold'}}>
              Deberás ingresar a la sección “Registro Klarway”, donde podrás
              encontrar tres resultados posibles:
              <ul>
                <li>
                  Aprobado: tu registro está completo y puedes realizar
                  exámenes.
                </li>
                <li>
                  En revisión: tu registro continúa en verificación y aún no
                  puedes realizar exámenes.
                </li>
                <li>
                  Desaprobado: tendrás que registrarte nuevamente y no puedes
                  realizar exámenes hasta que quede aprobado.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* <Box
        className={classes.boxes}
        sx={{
          flexDirection: { mobile: "column-reverse", laptop: "row" },
          margin: { mobile: "70px 30px", laptop: "30px" },
        }}
        style={{ alignItems: "baseline" }}
      >
        <Typography
          className={classes.text}
          sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
        >
          <a href="/emp" style={{ textDecoration: "none" }}>
            ¿Tengo que registrarme cada vez que realizo el examen?
          </a>
        </Typography>
        <IconButton
          aria-expanded={showAsk}
          aria-label="show more"
          onClick={() => {
            if (showAsk) {
              setShow(false);
            } else {
              setShow(true);
            }
            //   this.setState({
            //     showAsk: !showAsk,
            //   });
          }}
        >
          {showAsk === false ? <ExpandLess /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Typography
        className={classes.text}
        sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
      >
        <a href="/emp" style={{ textDecoration: "none" }}>
          ¿Dónde actualizo la información de mi registro?
        </a>
      </Typography>
      <Typography
        className={classes.text}
        sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
      >
        <a href="/emp" style={{ textDecoration: "none" }}>
          ¿Dónde encuentro el estado de mi registro manual?
        </a>
      </Typography>
      <Typography
        className={classes.text}
        sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
      >
        <a href="/inst/mac/1" style={{ textDecoration: "none" }}>
          ¿Cómo saber que MAC tengo?
        </a>
      </Typography>
      <Typography
        className={classes.text}
        sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
      >
        <a href="/perm" style={{ textDecoration: "none" }}>
          ¿Cómo cierro un programa abierto?
        </a>
      </Typography>
      <Typography
        className={classes.text}
        sx={{ flex: 1, fontSize: { mobile: "1.7rem", laptop: "1rem" } }}
      >
        <a href="/emp" style={{ textDecoration: "none" }}>
          ¿Qué pasa si no me toma correctamente la foto de mi identificación?
        </a>
      </Typography> */}
    </Box>
  );
};

const Pf = () => {
  const classes = useStyles();

  return (
    // <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px', }}>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "22px",
        marginBottom: "70px",
      }}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
        rel="stylesheet"
      />
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Bai Jamjuree",
          fontWeight: "bold",
          fontSize: { mobile: "3.3rem", laptop: "2.1rem" },
          marginLeft: "20px",
          textAlign: { mobile: "center", laptop: "left" },
        }}
      >
        Preguntas Frecuentes
      </Typography>
      <Divider />
      <PreguntaFrecuente></PreguntaFrecuente>
      {/* <Box>
            
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'column', },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                    style={{alignItems:"baseline"}}
                >     
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                       <a href='/emp' style={{textDecoration:"none"}} >¿Tengo que registrarme cada vez que realizo el examen?</a> 
                    </Typography>
                    <IconButton
                            aria-expanded={this.state.showAsk}
                            aria-label="show more"
                            onClick={() => {
                              this.setState({
                                showAsk: !this.state.showAsk,
                              });
                            }}
                          >
                            {this.state.showAsk ? <ExpandLess /> : <ExpandMoreIcon />}
                          </IconButton>
                </Box>
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        <a href='/emp' style={{textDecoration:"none"}}>¿Dónde actualizo la información de mi registro?</a>
                    </Typography>
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        <a href='/emp' style={{textDecoration:"none"}}>¿Dónde encuentro el estado de mi registro manual?</a>
                    </Typography>
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        <a href='/inst/mac/1' style={{textDecoration:"none"}}>¿Cómo saber que MAC tengo?</a>
                    </Typography>
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        <a href='/perm' style={{textDecoration:"none"}}>¿Cómo cierro un programa abierto?</a>
                    </Typography>
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        <a href='/emp' style={{textDecoration:"none"}}>¿Qué pasa si no me toma correctamente la foto de mi identificación?</a>
                    </Typography>
                </Box> */}

      {/* <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Abre la app de Klarway en la PC.
                    </Typography>
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Selecciona tu institución para luego registrarte
                    </Typography>
                    <Box
                        component="img"
                        src={examen1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Ingresa tu usuario y contraseña para acceder a la plataforma y buscar en ella el
examen que debes realizar. En caso de tener inconvenientes, deberás consultar con tu
institución educativa.
                    </Typography>
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Al acceder al examen, el sistema validará tu identidad, conforme al registro realizado.
Para ello, toma una fotografía de tu rostro.
                    </Typography>
                    <Box
                        component="img"
                        src={examen2}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                    <Box
                        component="img"
                        src={examen3}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Una vez abierto el examen, podras realizarlo con normalidad. Klarway solo realizara un
monitoreo de dicha instancia.
                    </Typography>
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ flex: 1, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Al finalizar el examen, podrás minimizar tu pantalla mientras esperas hasta que la
evaluación se termine de cargar. Luego, cierra la aplicación.
                    </Typography>
                    <Box
                        component="img"
                        src={examen4}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box> */}

      {/* {examen.map(({ text, imagen }) => {
                return (
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            flexDirection: { mobile: 'column', laptop: 'row' },
                            display: 'flex',
                            padding: '40px',
                            margin: '30px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                        }}
                    >
                        {text && (
                            <Typography
                                className={classes.text}
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px auto 0px 0px' },
                                }}
                            >
                                {text}
                            </Typography>
                        )}

                        <Box
                            component="img"
                            src={imagen}
                            className={classes.imagen}
                            sx={{
                                objectFit: { mobile: 'fill', laptop: 'contain' },
                                width: { mobile: '100%', laptop: '50%' },
                            }}
                        />
                    </Box>
                );
            })} */}
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    boxesHorizontal: {
      display: "flex",
      margin: "50px",
      justifyContent: "space-around",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "10px",
      boxShadow: "0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      WebkitBoxShadow: "0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      zIndex: 99,
      backgroundColor: "#fff",
    },
    boxes: {
      backgroundColor: "#fff",
      display: "flex",
      padding: "40px",
      // height: '50vh',
      margin: "30px",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: " 0 10px 60px 0 rgba(145, 151, 179, 0.15)",
      WebkitBoxShadow: " 0 10px 60px 0 rgba(145, 151, 179, 0.15)",
    },
    imagen: { margin: "20px auto", borderRadius: "10px" },
    imgTitle: { borderRadius: "10px" },
    imgBig: { width: "60vw", margin: "20px auto", borderRadius: "10px" },
    link: {
      color: "blue",
      fontWeight: "bold",
      textDecoration: "none",
      cursor: "pointer",
    },
    text: {
      maxWidth: "80vw",
      flex: 1,
      padding: "20px",
      textAlign: "center",
      fontFamily: "DINPro",
    },
    subMenu: { padding: "10px" },
    center: { margin: "50px", textAlign: "center" },
    noLink: { textDecoration: "none" },
  })
);
export default Pf;
