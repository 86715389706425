import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@material-ui/core/Divider';

import ex1 from '../../assets/exam/ex1.png';
import ex2 from '../../assets/emp/canvas/cnv0.png';
import ex3 from '../../assets/exam/ex3.png';
import ex4 from '../../assets/exam/ex4.png';
import examen1 from '../../assets/exam/examen1.png';
import examen2 from '../../assets/exam/examen2.png';
import examen3 from '../../assets/exam/examen3.png';
import examen4 from '../../assets/exam/examen4.png';
import exam2 from '../../assets/exam/exam2.png';
import exam1 from '../../assets/exam/exam1.png';
import dfgh from '../../assets/exam/dfgh.png'
import imgex from '../../assets/exam/imgex.png'
import guardadoex from '../../assets/exam/guardadoex.png'


const Exam = () => {
    const classes = useStyles();
    const examen = [
        {
            text: 'Abrir la app de Klarway en la computadora.',
            imagen: ex1,
        },
        {
            text: 'Loguear como alumno para ingresar a la plataforma de estudio e ingresar al examen',
            imagen: ex2,
        },
        {
            text: 'El sistema validará que el alumno es el que está registrado y para ello tomará una foto de su rostro.',

            imagen: ex3,
        },
        {
            imagen: ex4,
            text: 'El estudiante realizará el examen y al finalizar deberá esperar hasta que el examen se cargue, luego podrá cerrar la app de escritorio. Mientas carga, podrá minimizar la pantalla ',
        },
    ];
    return (
        <Box sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Al momento de realizar un Examen
            </Typography>
            <Divider />

            <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Abre la app de Klarway en la PC.
                    </Typography>
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >        
                    <Typography
                       className={classes.text}
                       sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Selecciona tu institución 
                    </Typography>
                    <Box
                        component="img"
                        src={examen1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>

                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Ingresa tu usuario y contraseña para acceder a la plataforma y buscar en ella el
examen que debes realizar. En caso de tener inconvenientes, deberás consultar con tu
institución educativa.
                    </Typography>
                    <Box
                        component="img"
                        src={dfgh}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Al acceder al examen, el sistema validará tu identidad, conforme al registro realizado.
Para ello, toma una fotografía de tu rostro.
                    </Typography>
                    <Box
                        component="img"
                        src={exam1}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                    <Box
                        component="img"
                        src={examen3}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Una vez abierto el examen, podrás realizarlo con normalidad. Klarway solo realizara un
monitoreo de dicha instancia.
                    </Typography>
                    <Box
                        component="img"
                        src={imgex}
                        className={classes.imagen}
                        sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                    />
                </Box>
                <Box
                    className={classes.boxes}
                    sx={{
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        margin: { mobile: '70px 30px', laptop: '30px' },
                    }}
                >        
                    <Typography
                        className={classes.text}
                        sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                    >
                        Al finalizar el examen, podrás minimizar tu pantalla mientras esperas hasta que la
evaluación se termine de cargar. Luego, cierra la aplicación.
                    </Typography>
                    <Box
                        component="img"
                        src={guardadoex}
                        className={classes.imagen}
                        sx={{ width: { mobile: '60%', laptop: '22vw' } }}
                    />
                </Box>

            {/* {examen.map(({ text, imagen }) => {
                return (
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            flexDirection: { mobile: 'column', laptop: 'row' },
                            display: 'flex',
                            padding: '40px',
                            margin: '30px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                        }}
                    >
                        {text && (
                            <Typography
                                className={classes.text}
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px auto 0px 0px' },
                                }}
                            >
                                {text}
                            </Typography>
                        )}

                        <Box
                            component="img"
                            src={imagen}
                            className={classes.imagen}
                            sx={{
                                objectFit: { mobile: 'fill', laptop: 'contain' },
                                width: { mobile: '100%', laptop: '50%' },
                            }}
                        />
                    </Box>
                );
            })} */}
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: '0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: '0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        boxes: {
            display: 'flex',
            padding: '20px 40px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            backgroundColor: '#fff',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgTitle: { borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { margin: 'auto 20px', fontFamily: 'DINPro' },
        subMenu: { padding: '10px' },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);
export default Exam;
