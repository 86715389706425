import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BsWindows, BsApple } from 'react-icons/bs';

import Card from '../../common/Card.jsx';

const PermSwitcher = () => {
    const classes = useStyles();

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: { mobile: '75vh', laptop: '100vh' },
                justifyContent: { mobile: 'space-around', laptop: 'space-evenly' },
            }}
        >
            {/* MAIN SECTION */}
            {/* <Toolbar /> */}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    marginTop: { mobile: '0px', laptop: '5vh' },
                    marginBottom: { mobile: '0px', laptop: '5vh' },
                    marginLeft: '108px',
                }}
            >
                <Typography
                    sx={{
                        display: 'inline',
                        fontFamily: 'DINPro-600',
                        fontSize: { mobile: '2.7rem', laptop: '47px' },
                        height: '56px',
                        letterSpacing: '-1.2px',
                        textAlign: { mobile: 'left', laptop: 'left' },
                        margin: { mobile: '20px 50px 20px 0px', laptop: '10px 0px 5px 0px' },
                    }}
                >
                    Permisos
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%', margin: 'auto' }}>
                
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Permisos cámara, video y grabación de pantalla'}
                        subtitle={'MacOS. Configuraciones de multimedia'}
                        icon={<BsApple />}
                        reactIcon={true}
                        link={'/perm/multi/m'}
                    />
                    <Card
                        title={'Cámara y micrófono'}
                        subtitle={'Windows. Configuraciones de multimedia'}
                        icon={<BsWindows />}
                        reactIcon={true}
                        link={'/perm/multi/w'}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Cerrar procesos abiertos'}
                        subtitle={'MacOS. Controlar procesos'}
                        icon={<BsApple />}
                        reactIcon={true}
                        link={'/perm/proc/m'}
                    />
                    <Card
                        title={'Cerrar procesos abiertos'}
                        subtitle={'Windows. Controlar procesos'}
                        icon={<BsWindows />}
                        reactIcon={true}
                        link={'/perm/proc/w'}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        title={'Cámaras virtuales'}
                        subtitle={'MacOS. Desinstalar cámaras virtuales'}
                        icon={<BsApple />}
                        reactIcon={true}
                        link={'/perm/vcam/m'}
                    />
                    <Card
                        title={'Cámaras virtuales'}
                        subtitle={'Windows. Desinstalar cámaras virtuales'}
                        icon={<BsWindows />}
                        reactIcon={true}
                        link={'/perm/vcam/w'}
                    />
                </Box>
                <Box
                    sx={{
                         display: 'flex',
                        marginRight: 2,
                        flexDirection: { mobile: 'column', laptop: 'row' },
                        justifyContent: 'end',
                        alignItems: 'rigth',
                    }}
                >
                    {/* <Card
                        title={'Permisos de instalación'}
                        subtitle={'MacOS. Permiso de desarrollador'}
                        icon={<BsApple />}
                        reactIcon={true}
                        link={'/perm/dev'}
                    /> */}
                    <Card
                        title={'Antivirus'}
                        subtitle={'Windows. Configuraciones de antivirus'}
                        icon={<BsWindows />}
                        reactIcon={true}
                        link={'/perm/antv'}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        box: {
            fontWeight: 'bold',
            color: '#0cbcd8',
            height: '90px',
            border: '1px solid',
            borderColor: '#2bf29c',
            borderRadius: '20px',
            backgroundColor: '#fff',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transition: 'all 0.4s ease-in-out',
            letterSpacing: '2px',
            '&:hover': {
                fontSize: '1.3rem',
            },
        },
        icon: { color: '#9bf7d3', fontSize: '5vh' },
        color: {
            background: 'linear-gradient(225deg, #009fff 0%, #29f39b 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
    })
);

export default PermSwitcher;
