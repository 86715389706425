import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import cnv0 from '../../assets/emp/canvas/cnv0.png';
import cnv1 from '../../assets/emp/canvas/cnv1.png';
import cnv2 from '../../assets/emp/canvas/cnv2.png';

import EmpCircuito from '../../common/EmpCircuito.jsx';
import EmpBody from '../../common/EmpBody';
import DocDesktop from './DocDesktop';
import NewEmp from './NewEmp';

const DocLms = ({ uni,tabN, type }) => {
    const classes = useStyles();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <NewEmp></NewEmp>
           
            {/* {(!tabN && tabN!==0) || tabN==1?
            <div>
                <Typography
                variant="h6"
                sx={{
                    fontSize: { mobile: '2.5rem', laptop: '1.2rem' },
                    fontFamily: 'Bai Jamjuree',
                    textAlign: 'center',
                    margin: { mobile: '5rem auto', laptop: '20px auto' },
                    color: '#14b0ee',
                }}
            >
                Descargar Klarway en el celular y seguir los siguientes pasos para completar el registro !
            </Typography>

            <Box className={classes.boxesHorizontal}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontFamily: 'Bai Jamjuree',
                            textAlign: 'center',
                            marginTop: '10px',
                            fontSize: { mobile: '2rem', laptop: '1.1rem' },
                            color: '#050505',
                        }}
                    >
                        Iniciar la aplicación de escritorio y acceder con la cuenta del instituto para continuar
                    </Typography>

                    <Box component="img" className={classes.image} src={cnv0} sx={{ margin: '20px auto' }} />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: { mobile: 'center', laptop: 'space-between' },
                    margin: '60px auto',
                    width: '33vw',
                }}
            >
                <Box
                    sx={{
                        display: { mobile: 'none', laptop: 'initial' },
                        margin: { mobile: '0px auto', laptop: '0px' },
                    }}
                >
                    <SvgIcon component={ArrowDownwardIcon} className={classes.icon} />
                </Box>
                <SvgIcon component={ArrowDownwardIcon} className={classes.icon} />
            </Box>
            {type === 'canvas' ? (
                <Box className={classes.boxesHorizontal}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: { mobile: 'column', laptop: 'row' },
                            }}
                        >
                            <Box className={classes.boxesHorizontal}>
                                <Box
                                    component="img"
                                    src={cnv1}
                                    className={classes.imgBig}
                                    sx={{ width: { laptop: '30vw' }, maxHeight: { laptop: '40vh' } }}
                                />
                            </Box>
                            <SvgIcon
                                component={ArrowDownwardIcon}
                                className={classes.icon}
                                sx={{ transform: { laptop: 'rotate(-90deg)' } }}
                            />

                            <Box
                                component="img"
                                src={cnv2}
                                className={classes.imgBig}
                                sx={{ width: { laptop: '30vw' }, maxHeight: { laptop: '40vh' } }}
                            />
                        </Box>
                        <Typography
                            className={classes.center}
                            variant="body1"
                            sx={{ fontSize: { mobile: '1.8rem', laptop: '1rem' } }}
                        >
                            Buscar el ícono del código QR en el margen derecho de la pantalla. Al hacerle click, se
                            abrirá un código QR para escanear en pantalla. Seguir el paso a paso que se muestra en las
                            siguientes imágenes.
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box className={classes.boxesHorizontal}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { mobile: 'column', laptop: 'row' },
                            alignItems: 'center',
                        }}
                    >
                        <img src={cnv2} className={classes.imgBig} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                className={classes.center}
                                variant="body1"
                                sx={{ fontSize: { mobile: '1.8rem', laptop: '1rem' } }}
                            >
                                Al ingresar la primera vez, se abrirá un código QR para escanear y realizar el
                                registro.
                            </Typography>
                            <Typography
                                className={classes.center}
                                variant="body1"
                                sx={{ fontSize: { mobile: '1.8rem', laptop: '1rem' }, margin: '15px auto' }}
                            >
                                <b> Seguír </b>
                                el paso a paso que se muestra en las siguientes imágenes.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { mobile: 'center', laptop: 'center' },
                    margin: { mobile: '60px auto', laptop: '60px 0px' },
                    width: '23vw',
                }}
            >
                {/* <Box
                    sx={{
                        display: { mobile: 'none', laptop: 'initial' },
                        margin: { mobile: '0px auto', laptop: '0px' },
                    }}
                >
                    <SvgIcon component={ArrowDownwardIcon} className={classes.icon} />
                </Box> */}
                {/* <SvgIcon component={ArrowDownwardIcon} className={classes.icon} /> */}
            {/* </Box> */}

            {/* <EmpCircuito uni={uni} type="lms" /> */}

            {/* <Box */}
                {/* sx={{ */}
                    {/* display: 'flex',
                    alignItems: 'center',
                    justifyContent: { mobile: 'center', laptop: 'center' },
                    margin: { mobile: '60px auto', laptop: '60px 0px' },
                    width: '23vw',
                }}
            > */}
                {/* <Box
                    sx={{
                        display: { mobile: 'none', laptop: 'initial' },
                        margin: { mobile: '0px auto', laptop: '0px' },
                    }}
                >
                    <SvgIcon component={ArrowDownwardIcon} className={classes.icon} />
                </Box> */}
                {/* <SvgIcon component={ArrowDownwardIcon} className={classes.icon} /> */}
            {/* </Box>

            {/* TERCERA SECCION DE IMAGENES */}

            {/* <EmpBody type={'lms'} /> */}
            {/* </div>
            :
            <DocDesktop uni={uni}></DocDesktop>
            // } */} 
            
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesContainer: { maxWidth: '80vw', display: 'flex', justifyContent: 'space-around', alignItems: 'center' },
        boxes: {
            display: 'flex',
            flexDirection: 'column',
            margin: '10px',
            width: '17vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            transition: '.3s all ease',
            backgroundColor: '#fff',
            '&:hover': { transform: 'scale(1.1)' },
        },
        boxesHorizontal: {
            display: 'flex',
            margin: '50px 20px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            backgroundColor: '#fff',
        },
        imagen: {
            // height: '66vh',
            margin: '0 auto',
            borderRadius: '10px',
            objectFit: 'fill',
        },

        imgBig: { margin: '20px auto', borderRadius: '5px', objectFit: 'contain' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' },
        text: { color: '#14b0ee', fontFamily: 'DINPro' },
        center: { margin: '50px', textAlign: 'center' },
        icon: { fontSize: '70px', color: '#9bf7d3' },
        rightIcon: { fontSize: '70px', color: '#9bf7d3', transform: 'rotate(180deg)' },
        leftIcon: { display: 'flex', justifyContent: 'flex-end', margin: '30px auto', marginRight: '7vw' },
        lineColor: { color: '#2bf29c', backgroundColor: '#2bf29c', opacity: 0.5 },
    })
);

export default DocLms;
