import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@material-ui/core/Icon';

import MenuItem from './MenuItem';
import klarway from '../assets/sidebar/klarway.svg';
import { data } from '../utils/data.js';

const SideBar = ({ location, drawerWidth, check, mobile }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                justifyContent: 'space-around',
                margin: drawerWidth === 120 ? '0px auto' : '0px 20px',
                transition: '0.5s all ease-in-out',
                textAlign: 'center',
            }}
        >
            <Icon className={classes.iconRoot} onClick={() => navigate('/')}>
                <Box
                    component="img"
                    className={classes.imageIcon}
                    src={klarway}
                    sx={{
                        height: { mobile: '80%', laptop: '50%' },
                    }}
                />
            </Icon>

            {/* </Box> */}

            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    margin: 'auto',
                }}
            >
                {data.map((category, index) => (
                    <MenuItem
                        {...category}
                        key={index}
                        path={location}
                        drawerWidth={drawerWidth}
                        check={check}
                        mobile={mobile}
                    />
                ))}
            </List>
        </Box>
    );
};

const MyDrawer = (props) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [drawerWidth, setDrawerWidth] = React.useState(120);
    const [check, setCheck] = React.useState(false);

    const classes = useStyles();
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        setMobileOpen(false);
    }, [location.pathname]);

    return (
        <Box
            sx={{
                boxShadow: '0 10px 80px 0 rgba(145, 151, 179, 0.2)',
                position: { laptop: 'absolute' },
                overflowY: 'hidden',
            }}
        >
            <CssBaseline />
            <Toolbar
                position="fixed"
                sx={{
                    display: { laptop: 'none' },
                    width: { mobile: `100%`, laptop: `calc(100% - ${drawerWidth}px)` },
                    ml: { desktop: `${drawerWidth}px` },
                    paddingTop: '20px',
                    height: '150px',
                    marginBottom: '20px',
                    opacity: 0.7,
                    transition: '.3s width ease-in-out',
                    backgroundColor: 'rgba(0,0,0, .08)',
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    size="large"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { laptop: 'none' }, marginLeft: '30px' }}
                >
                    <MenuIcon className={classes.icon} />
                </IconButton>
            </Toolbar>
            <Box
                component="nav"
                onMouseEnter={() => {
                    setDrawerWidth(300);
                    // setCheck(true);
                }}
                onMouseLeave={() => {
                    setDrawerWidth(120);
                    // setTimeout(() => {
                    //     setCheck(false);
                    // }, 200);
                }}
                sx={{
                    width: { mobile: '100vw', tablet: drawerWidth },
                    flexShrink: { tablet: 0 },
                    transition: '.3s width ease-in-out',
                }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { laptop: 'none' },
                        '& .MuiDrawer-paper': {
                            mobile: { boxSizing: 'border-box', width: '52vw' },
                        },
                    }}
                >
                    <SideBar location={props.location} mobile={mobileOpen} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        textAlign: 'center',
                        display: { mobile: 'none', laptop: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            transition: '0.3s width ease-in-out',
                        },
                    }}
                    open
                >
                    <SideBar location={props.location} drawerWidth={drawerWidth} check={check} />
                </Drawer>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        icon: {
            fontSize: '4rem',
        },
        img: { width: '100%', objectFit: 'fill', margin: 'auto', padding: '2px', height: '3rm' },
        imageIcon: {},
        iconRoot: {
            cursor: 'pointer',
            height: '15%',
            width: '50%',
            alignContent: 'center',
            marginTop: '50px',
            textAlign: 'center',
            //justifyContent: 'center',
        },
    })
);

export default MyDrawer;
