import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import av3 from '../../../assets/perm/antiVirus/av3.png';
import av4 from '../../../assets/perm/antiVirus/av4.png';
import av5 from '../../../assets/perm/antiVirus/av5.png';
import av6 from '../../../assets/perm/antiVirus/av6.png';
import av8 from '../../../assets/perm/antiVirus/av8.png';

const Avast = () => {
    const classes = useStyles();
    const avast = [
        {
            text: 'Abrir la interfaz de usuario de Avast Antivirus y seleccionar ☰ Menú ▸ Configuración.',
            imagen: av4,
        },
        {
            text: 'Asegurarse de que esté seleccionada la pestaña General y hacer clic en Excepciones.',
            imagen: av5,
        },
        {
            text: 'Hacer clic en el botón Añadir una excepción.',

            imagen: av6,
        },
        {
            imagen: av8,
            text: 'Hacer clic en Examinar, marcar la casilla que hay junto al archivo o la carpeta que desea agregar a la lista de excepciones y, a continuación, hacer clic en Aceptar.',
        },
    ];
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    width: { mobile: '90vw', laptop: '40vw' },
                    height: { mobile: '20vh', laptop: '15.3rem' },
                    borderRadius: '10px',
                    margin: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                    WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                }}
                component="img"
                src={av3}
            />

            {avast.map(({ text, imagen }) => {
                return (
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            flexDirection: { mobile: 'column', laptop: 'row' },
                            display: 'flex',
                            padding: '40px',
                            margin: '30px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
                        }}
                    >
                        {text && (
                            <Typography
                                className={classes.text}
                                sx={{
                                    fontSize: { mobile: '1.8rem', laptop: '1rem' },
                                    margin: { mobile: ' 2rem auto 0px 0px', laptop: '0px auto 0px 0px' },
                                }}
                            >
                                {text}
                            </Typography>
                        )}

                        <Box
                            component="img"
                            src={imagen}
                            className={classes.imagen}
                            sx={{
                                objectFit: { mobile: 'fill', laptop: 'contain' },
                                width: { mobile: '100%', laptop: '50%' },
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '50px',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            zIndex: 99,
            backgroundColor: '#fff',
        },
        boxes: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgTitle: { borderRadius: '10px' },
        imgBig: { width: '60vw', margin: '20px auto', borderRadius: '10px' },
        link: { color: 'blue', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' },
        text: { maxWidth: '80vw', flex: 1, padding: '20px', textAlign: 'center', fontFamily: 'DINPro' },
        subMenu: { padding: '10px' },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);
export default Avast;
