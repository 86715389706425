import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MdBlock } from 'react-icons/md';
import { HiOutlineChip } from 'react-icons/hi';

import { requirements } from '../../utils/data.js';

const DocRequires = () => {
    const classes = useStyles();
    const categorias = [
        'Sistema Operativo',
        'Procesador',
        'RAM',
        'Espacio Disponible',
        'Cámara',
        'Micrófono',
        'Internet',
        'Batería',
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '22px',
                overFlow: 'none',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />

            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Requisitos Recomendados
            </Typography>
            <Divider />
            <TableContainer className={classes.tableContainer} component={Paper} sx={{ maxWidth: { laptop: '79vw' } }}>
                <Table className={classes.table} aria-label="simple table">
                    <TableRow className={classes.tableHead}>
                        <TableCell
                            component={Paper}
                            padding="none"
                            align="center"
                            sx={{
                                // backgroundColor: '#03a3f5',
                                color: '#2bf29c',
                                maxHeight: '53px',
                            }}
                        >
                            <HiOutlineChip className={classes.icon} />
                        </TableCell>
                        {categorias.map((cat) => (
                            <TableCell
                                component={Paper}
                                align="center"
                                className={
                                    cat === 'Sistema Operativo' || cat === 'Espacio Disponible' ? classes.system : null
                                }
                            >
                                {cat}
                            </TableCell>
                        ))}
                    </TableRow>

                    <TableBody className={classes.tableBody}>
                        {requirements.map((row) => (
                            <TableRow className={classes.tableRow} key={row.name}>
                                <TableCell component="th" align="center" scope="row" sx={{ fontWeight: 'bold' }}>
                                    {row.name}
                                </TableCell>
                                <TableCell align="center" sx={{ flex: 1, maxHeight: '4.3rem', minHeight: '4.6rem' }}>
                                    {row.os}
                                </TableCell>
                                <TableCell align="center">{row.proc || <MdBlock />}</TableCell>
                                <TableCell align="center">{row.ram || <MdBlock />}</TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ flex: 1, maxHeight: '4.3rem', minHeight: '4.6rem', textAlign: 'center' }}
                                >
                                    {row.mem || <MdBlock />}
                                </TableCell>
                                <TableCell align="center">{row.cam || <MdBlock />}</TableCell>
                                <TableCell align="center">{row.mic || <MdBlock />}</TableCell>
                                <TableCell align="center">{row.inet || <MdBlock />}</TableCell>
                                <TableCell align="center">{row.bat || <MdBlock />}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        tableContainer: {
            display: 'flex',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            margin: 'auto 10px',
            marginTop: '5vh',
            flexDirection: 'row',
        },
        table: {
            display: 'flex',
        },
        tableHead: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 'bold',
        },
        tableBody: {
            display: 'flex',
            justifyContent: 'space-around',
            flexGrow: 1,
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'column',
        },
        icon: { fontSize: '50px' },
        system: { flex: 1, maxHeight: '4.3rem', minHeight: '4.6rem', textAlign: 'center' },
    })
);

export default DocRequires;
