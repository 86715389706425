import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button, SvgIcon } from '@material-ui/core';
import cnv3 from '..//assets/emp/canvas/cnv3.png';
import cnv4 from '../assets/emp/canvas/cnv4.png';

import sys2 from '../assets/emp/sys/sys2.png';
import sys3 from '../assets/emp/sys/sys3.png';
import sys4 from '../assets/emp/sys/sys4.png';
import sys5 from '../assets/emp/sys/sys5.png';
import sys7 from '../assets/emp/sys/sys7.png';

import siglo from '../assets/emp/sys/u_siglo.png';
import ippregistro from '../assets/emp/sys/ippregistro.png';

import teclab from '../assets/emp/sys/u_teclab.png';
import Avatar from '@mui/material/Avatar';

const CircuitoEmp = ({ uni, type }) => {
    const universidad = uni === 'UES21' ? 'Universidad Siglo 21' : uni;
    const instituto = uni === 'UES21' ? siglo : uni === 'TECLAB' ? teclab : uni === 'IPP' ? ippregistro : null;

    const classes = useStyles();

    return (
        <div>
            {/* PRIMERA SECCION DE IMAGENES */}

            <Box className={classes.boxesContainer} sx={{ flexDirection: { mobile: 'column', laptop: 'row' }, display:'flex' }}>
                <Box
                    className={classes.boxes}
                    sx={{
                        width: { mobile: '65vw', laptop: '20vw' },
                        height: { mobile: '72vh', laptop: '73vh' },
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    }}
                >
                    <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>1</Avatar>
                    
                    <Box
                        component="img"
                        src={sys2}
                        className={classes.imagen}
                        sx={{
                            width: { mobile: '65vw', laptop: '20vw' },
                            height: { mobile: '65vh', laptop: '66vh' },
                        }}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        Selecciona <i>"Ingresar Ahora"</i>
                    </Typography>
                </Box>

                
                {/* <SvgIcon
                    component={ArrowForwardIcon}
                    className={classes.icon}
                    sx={{
                        transform: {
                            mobile: 'rotate(90deg)',
                            laptop: 'rotate(0deg)',
                        },
                    }}
                /> */}
                <Box
                    className={classes.boxes}
                    sx={{
                        width: { mobile: '65vw', laptop: '20vw' },
                        height: { mobile: '72vh', laptop: '73vh' },
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    }}
                >
                    <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>2</Avatar>

                    <Box
                        component="img"
                        src={sys3}
                        sx={{ width: { mobile: '65vw', laptop: '20vw' }, height: { mobile: '65vh', laptop: '66vh' } }}
                        className={classes.imagen}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        Acepta terminos y condiciones
                    </Typography>
                </Box>
                {/* <SvgIcon
                    component={ArrowForwardIcon}
                    className={classes.icon}
                    sx={{
                        transform: {
                            mobile: 'rotate(90deg)',
                            laptop: 'rotate(0deg)',
                        },
                    }}
                />{' '} */}
                <Box
                    className={classes.boxes}
                    sx={{
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                        width: { mobile: '65vw', laptop: '20vw' },
                        height: { mobile: '72vh', laptop: '73vh' },
                    }}
                >
                    <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>3</Avatar>
                    <Box
                        component="img"
                        sx={{ width: { mobile: '65vw', laptop: '20vw' }, height: { mobile: '65vh', laptop: '66vh' } }}
                        src={type === 'lms' ? cnv3 : sys4}
                        className={classes.imagen}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        {type === 'lms'
                            ? 'Haz click en el simbolo QR'
                            : 'Busca tu Institución: ' + '"' + universidad + '"'}
                    </Typography>
                </Box>
            </Box>
            {/* FIN PRIMERA SECCION */}

            <Box
                sx={{
                    display: 'flex',
                    margin: '30px auto',
                    marginRight: { mobile: '0px', laptop: '8vw' },
                    justifyContent: { mobile: 'center', laptop: 'flex-end' },
                }}
            >
                {/* <SvgIcon
                    component={ArrowForwardIcon}
                    className={classes.icon}
                    sx={{
                        transform: 'rotate(90deg)',
                    }}
                /> */}
            </Box>



            {/* SEGUNDA SECCION DE IMAGENES */}

            <Box className={classes.boxesContainer} sx={{ flexDirection: { mobile: 'column-reverse', laptop: 'row' } }}>


            <Box
                    className={classes.boxes}
                    sx={{
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                        width: { mobile: '65vw', laptop: '20vw' },
                        height: { mobile: '72vh', laptop: '73vh' },
                    }}
                >
                    <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>4</Avatar>
                    <Box
                        component="img"
                        src={type === 'lms' ? cnv4 : instituto}
                        className={classes.imagen}
                        sx={{ width: { mobile: '65vw', laptop: '20vw' }, height: { mobile: '65vh', laptop: '66vh' } }}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        {type === 'lms' ? 'Escanear correctamente el codigo QR' : ' Ingresar usuario y contraseña'}
                    </Typography>
                </Box>



                
                {/* <SvgIcon
                    component={ArrowForwardIcon}
                    className={classes.icon}
                    sx={{
                        transform: {
                            mobile: 'rotate(90deg)',
                            laptop: 'rotate(180deg)',
                        },
                    }}
                />{' '} */}
                <Box
                    className={classes.boxes}
                    sx={{
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                        width: { mobile: '65vw', laptop: '20vw' },
                        height: { mobile: '72vh', laptop: '73vh' },
                    }}
                >
                    <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>5</Avatar>
                    <Box
                        component="img"
                        src={sys5}
                        className={classes.imagen}
                        sx={{ width: { mobile: '65vw', laptop: '20vw' }, height: { mobile: '65vh', laptop: '66vh' } }}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        Sigue los pasos y toma una foto de tu rostro
                    </Typography>
                </Box>


                <Box
                    className={classes.boxes}
                    sx={{
                        width: { mobile: '65vw', laptop: '20vw' },
                        height: { mobile: '72vh', laptop: '73vh' },
                        '&:hover': { transform: { mobile: 'none', laptop: 'scale(1.1)' } },
                    }}
                >
                    <Avatar sx={{ bgcolor: '#14b0ee', marginBottom: '-30px', marginLeft: '-20px'}}>6</Avatar>
                    <Box
                        component="img"
                        src={sys7}
                        className={classes.imagen}
                        sx={{ width: { mobile: '65vw', laptop: '20vw' }, height: { mobile: '65vh', laptop: '66vh' } }}
                    />
                    <Typography
                        sx={{ padding: '5px', fontWeight: 'bold', fontSize: { mobile: '2.5rem', laptop: '1rem' } }}
                    >
                        Toma una foto de la identificación personal
                    </Typography>
                </Box>
                {/* <SvgIcon
                    component={ArrowForwardIcon}
                    className={classes.icon}
                    sx={{
                        transform: {
                            mobile: 'rotate(90deg)',
                            laptop: 'rotate(180deg)',
                        },
                    }}
                />{' '} */}
                
            </Box>
            {/* FIN SEGUNDA SECCION */}
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesContainer: {
            maxWidth: '80vw',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            margin: '10px auto',
        },
        boxes: {
            display: 'flex',
            flexDirection: 'column',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'start',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            transition: '.3s all ease',
            backgroundColor: '#fff',
        },
        imagen: {
            margin: '0 auto',
            borderRadius: '10px',
            objectFit: 'fill',
        },
        icon: { fontSize: '70px', color: '#9bf7d3', margin: '50px 10px' },
        boton: {

            width:'25px',
            height:'25px',
            backgroundColor:'#14b0ee',
            margin: '0px',
            padding:'10px',
            // -webkit-border-radius: 50px,
            // -moz-border-radius: 50px,
            borderRadius: '50px',
            fontSize:'11px',
            lineHeight:'32px',
            textTransform: 'uppercase',
            float:'left',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
    })
);

export default CircuitoEmp;
