import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Avast from './Antivirus/Avast';
import Defender from './Antivirus/Defender';
import Norton from './Antivirus/Norton';
import Kaspersky from './Antivirus/Kaspersky';
import Mcaffe from './Antivirus/Mcaffe';

const AntiVirus = ({ type }) => {
    console.log(type);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Antivirus - {type[0].toUpperCase() + type.substring(1)}
            </Typography>
            <Divider />

            {type === 'avast' && <Avast />}
            {type === 'defender' && <Defender />}
            {type === 'norton' && <Norton />}
            {type === 'kaspersky' && <Kaspersky />}
            {type === 'mcaffe' && <Mcaffe />}
        </Box>
    );
};

export default AntiVirus;
