import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import paWin1 from '../../assets/perm/procAb/paWin1.png';
import paWin2 from '../../assets/perm/procAb/paWin2.png';
import paWin3 from '../../assets/perm/procAb/paWin3.png';

import paMac1 from '../../assets/perm/procAb/paMac1.png';
import paMac2 from '../../assets/perm/procAb/paMac2.png';
import pam1 from '../../assets/perm/procAb/pam1.png';
import pam2 from '../../assets/perm/procAb/pam2.png';
import pam3 from '../../assets/perm/procAb/pam3.png';
import pam4 from '../../assets/perm/procAb/pam4.png';
import pam5 from '../../assets/perm/procAb/pam5.png';

const ProcAbiertos = ({ type }) => {
    const classes = useStyles();

    const procAbiertosWin = [
        { text: 'Busca Administrador de Tareas en el buscador de Windows.', imagen: paWin2 },
        { text: 'Selecciona: "Más detalles".', imagen: paWin1 },
        {
            text: 'Ingresa a la solapa Procesos, luego haz click derecho sobre los programas a cerrar.',
            finalText: 'Por último selecciona "Finalizar Tarea".',
            imagen: paWin3,
            column: true,
        },
    ];

    const procAbiertosMac = [


        { text:"Busca utilidades en el Finder, tal como lo muestra la imagen.", imagen: pam1},
        { text:"Busca el icono de “Monitor de Actividad.“", imagen: pam2 },
        { text:"Luego, localiza el proceso en la ventana “Monitor de Actividad”.", imagen: pam3},
        { text:"Una vez localizado, haz click en la cruz marcada en la imagen. Esto abrirá una ventana de diálogo.", imagen: pam4},
        { text:"Una vez en el cuadro de diálogo, selecciona la opción “forzar salida” para cerrar el proceso.", imagen: pam5 },




        // { text: 'Buscar en el cajón de aplicaciones "Monitor de actividad"', imagen: paMac1 },
        // {
        //     text: 'Seleccionar el proceso que Klarway detecta y hacer doble click en el mismo.',
        //     finalText: 'Por último, seleccionar SALIR.',
        //     imagen: paMac2,
        //     column: true,
        // },
    ];

    const procesos = type === 'm' ? procAbiertosMac : procAbiertosWin;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px', marginBottom: '70px' }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600&family=Julius+Sans+One&display=swap"
                rel="stylesheet"
            />
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'Bai Jamjuree',
                    fontWeight: 'bold',
                    fontSize: { mobile: '3.3rem', laptop: '2.1rem' },
                    marginLeft: '20px',
                    textAlign: { mobile: 'center', laptop: 'left' },
                }}
            >
                Cerrar Procesos Abiertos ({type === 'm' ? 'Mac' : 'Windows'})
            </Typography>
            <Divider />
            <Box sx={{
                            margin: { mobile: '70px 30px', laptop: '30px' },
                            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        
                        }}>
                {procesos.map(({ text, text2, imagen, finalText, column }) => {
                    const direction = column ? 'column' : 'row';
                    return (
                        <Box className={classes.boxes}
                        sx={{
                            margin: { mobile: '70px 30px', laptop: '30px' },
                            flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                        
                        }}>
                            {text && (
                                <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    {text}
                                </Typography>
                            )}
                            {text2 && (
                                <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    {text2}
                                </Typography>
                            )}

                            <Box
                                component="img"
                                src={imagen}
                                className={classes.imagen}
                                sx={{ width: { mobile: '80%', laptop: '22vw' } }}
                            />

                            {/* {finalText && (
                                <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    {finalText}
                                </Typography>
                            )} */}
                        </Box>
                        
                    );
                })}
            </Box>

            <Box
                    className={classes.boxes}
                    sx={{
                        margin: { mobile: '70px 30px', laptop: '30px' },
                        flexDirection: { mobile: 'column-reverse', laptop: 'row' },
                    }}
                >
            <Typography
                                className={classes.text}
                                sx={{ maxWidth: { laptop: '30vw' }, fontSize: { mobile: '1.7rem', laptop: '1rem' } }}
                                >
                                    Por último selecciona "Finalizar Tarea".
                                </Typography>
                                </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        boxesHorizontal: {
            display: 'flex',
            margin: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            zIndex: 99,
        },
        boxes: {
            backgroundColor: '#fff',

            display: 'flex',
            padding: '40px',
            // height: '50vh',
            margin: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
            WebkitBoxShadow: ' 0 10px 60px 0 rgba(145, 151, 179, 0.15)',
        },
        imagen: { margin: '20px auto', borderRadius: '10px' },
        imgBig: {
            width: '40vw',
            margin: '20px auto',
            borderRadius: '10px',
            alignItems: 'flex',
            justifyContent: 'flex',
            width: '100%',
        },
        link: {
            color: 'blue',
            fontWeight: 'bold',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        text: {
            flexGrow: 1,
            padding: '20px',
            textAlign: 'center',
            fontFamily: 'DINPro'
        },
        center: { margin: '50px', textAlign: 'center' },
        noLink: { textDecoration: 'none' },
    })
);

export default ProcAbiertos;
